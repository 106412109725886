import { makeStyles } from "@material-ui/core";

export const backgroundCardStyle = makeStyles({
    root: {
        background: '#F5F5F5',
        borderRadius: '10px',
        width: '98%',
        position: 'relative',
        top: '1em',
        border: '1px solid rgba(0, 0, 0, 1)'
    }
})

export const manageItemStyle = makeStyles({
    root: {
        '@media (max-width: 960px)': {
            top: '3em',
            position: 'relative'
        },
        '@media (min-width: 600px)': {
            left: '0.5em'
        }
    }
})

export const backgroundStyleForPreorderInList = makeStyles({
    root: {
        background: '#FFFFFF',
        border: '1px solid #A9A9A9',
        borderRadius: '5px',
    }
});