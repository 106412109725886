import { Grid, Typography, makeStyles } from "@material-ui/core";

import Tab3Img1 from "../../../../assets/img/tutorial/tab3img1.svg"
import Tab3Img2 from "../../../../assets/img/tutorial/tab3img2.svg"


const styles = makeStyles({
    tutorialPageTitle: {
        fontFamily: 'Be Vietnam Pro',
        fontSize: '30px',
        fontWeight: 600,
        lineHeight: '38px',
        letterSpacing: '0em',
        textAlign: 'center',
    },
    tutorialText: {
        fontFamily: 'Be Vietnam Pro',
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '20px',
        letterSpacing: '0em',
        textAlign: 'center',
    },
    tutorialPageSubtitle: {
        fontFamily: 'Be Vietnam Pro',
        fontSize: '24px',
        fontWeight: 600,
        lineHeight: '30px',
        letterSpacing: '0em',
        textAlign: 'center',
    }
});


export const MobileTab3 = () => {
    const style = styles();

    return (
        <>
            <Typography className={style.tutorialPageTitle} style={{height: 0}}>Mettere in prestito</Typography>
            <Grid container direction="column" justifyContent="center" alignContent="center">
                <Grid item><img src={Tab3Img1}/></Grid>
                <Grid item><img src={Tab3Img2}/></Grid>
            </Grid>
            <Typography className={style.tutorialPageSubtitle} style={{height: 0}}>
                Metto in prestito
            </Typography>
            <Typography className={style.tutorialText}>
                Una volta caricato un’oggetto sul sito, questo sarà reso disponibile agli utente per gli scambi.
                Puoi vedere e gestire i tuoi oggetti dalla sezione “Metto in prestito”
            </Typography>
        </>
    );
}