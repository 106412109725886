import { makeStyles, useMediaQuery } from "@material-ui/core"
import { DefaultToastOptions, ToastBar, Toaster, toast } from "react-hot-toast"
const styles = makeStyles({
    button: {
        background: 'none',
        color: 'white',
        border: 'none',
    }
})
export const CustomToaster = () => {
    const classes = styles();
    const isDesktop = !useMediaQuery('(max-width: 600px)');
    const toastOptions: DefaultToastOptions = {
        duration: 10000,
        style: {
            border: '1px solid #713200',
            padding: '25px 29px',
            backgroundColor: 'rgba(24, 27, 29, 0.7)',
            color: '#FFF',
            fontFamily: 'Be Vietnam Pro',
            fontSize: '16px',
            fontWeight: '600',
            maxWidth: 'none',
            width: '84%',
            borderRadius: '20px'
        }
    }
    return (
        <Toaster toastOptions={toastOptions} position="bottom-center">
            {(t) => (
                <ToastBar position="bottom-center" toast={t}>
                    {({ icon, message }) => (
                        <>
                            <div style={{ display: 'flex', flexDirection: 'row', width:'100%', alignItems: 'center', position: 'relative'}}>

                                <div>{icon}</div>
                                <div>{message}</div>
                                {t.type !== 'loading' && (
                                    <div style={{marginLeft: 'auto'}}>
                                        <svg style={{ width: isDesktop ? '25px' : '20px', height: isDesktop ? '25px' : '20px', cursor: 'pointer', flexShrink: 0 }} onClick={() => toast.dismiss(t.id)} width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M24.6615 0.323765C24.2298 -0.107922 23.5287 -0.107922 23.097 0.323765L12.5 10.9351L4.42311 2.86961L1.88824 0.338524C1.45653 -0.0931628 0.755479 -0.0931628 0.323777 0.338524C-0.107926 0.77021 -0.107926 1.47124 0.323777 1.90293L2.85864 4.43401L10.9355 12.4995L0.338536 23.1109C-0.0931662 23.5426 -0.0931662 24.2436 0.338536 24.6753C0.556232 24.8893 0.836654 25 1.12077 25C1.40488 25 1.68899 24.893 1.903 24.6753L12.5 14.0639L19.684 21.2366L23.1118 24.6606C23.3295 24.8746 23.6099 24.9852 23.894 24.9852C24.1781 24.9852 24.4622 24.8782 24.6762 24.6606C25.1079 24.2289 25.1079 23.5278 24.6762 23.0962L21.2484 19.6722L14.0645 12.4995L24.6615 1.88817C25.0932 1.45648 25.0932 0.755451 24.6615 0.323765Z" fill="white" />
                                        </svg>
                                    </div>
                                )}
                            </div>
                        </>
                    )}
                </ToastBar>
            )}
        </Toaster>
    )
}