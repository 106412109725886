import { Grid, Typography, makeStyles } from "@material-ui/core";

import Tab6Img1 from "../../../../assets/img/tutorial/tab6img1.svg"
import Tab6Img2 from "../../../../assets/img/tutorial/tab6img2.svg"


const styles = makeStyles({
    tutorialPageTitle: {
        fontFamily: 'Be Vietnam Pro',
        fontSize: '30px',
        fontWeight: 600,
        lineHeight: '38px',
        letterSpacing: '0em',
        textAlign: 'center',
    },
    tutorialText: {
        fontFamily: 'Be Vietnam Pro',
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '20px',
        letterSpacing: '0em',
        textAlign: 'center',
    },
    tutorialPageSubtitle: {
        fontFamily: 'Be Vietnam Pro',
        fontSize: '24px',
        fontWeight: 600,
        lineHeight: '30px',
        letterSpacing: '0em',
        textAlign: 'center',
    }
});


export const MobileTab6 = () => {
    const style = styles();

    return (
        <>
            <Typography className={style.tutorialPageTitle} style={{ height: 0 }}>Chiedere in prestito</Typography>
            <Grid container direction="column" justifyContent="center" alignContent="center">
                <Grid item><img src={Tab6Img1} /></Grid>
                <Grid item><img src={Tab6Img2} /></Grid>
            </Grid>
            <Typography className={style.tutorialPageSubtitle} style={{ height: 0 }}>
                Chiedo in prestito
            </Typography>
            <Typography className={style.tutorialText}>
                Seleziona sul calendario i giorni in cui vuoi prendere in prestito l’oggetto e clicca su “invia la mia richiesta”
            </Typography>
        </>
    );
}