import CirclesBar_1 from "../../../assets/img/tutorial/circles-bar-1.svg"
import CirclesBar_2 from "../../../assets/img/tutorial/circles-bar-2.svg"
import CirclesBar_3 from "../../../assets/img/tutorial/circles-bar-3.svg"
import CirclesBar_4 from "../../../assets/img/tutorial/circles-bar-4.svg"
import CirclesBar_5 from "../../../assets/img/tutorial/circles-bar-5.svg"
import CirclesBar_6 from "../../../assets/img/tutorial/circles-bar-6.svg"
import CirclesBar_7 from "../../../assets/img/tutorial/circles-bar-7.svg"
import CirclesBar_8 from "../../../assets/img/tutorial/circles-bar-8.svg"
import CirclesBar_9 from "../../../assets/img/tutorial/circles-bar-9.svg"
import CirclesBar_10 from "../../../assets/img/tutorial/circles-bar-10.svg"
import { CSSProperties } from "react"


export type TMobileTutorialProgressStepNumber = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;

export interface IMobileTutorialProgressProps {
    step: TMobileTutorialProgressStepNumber;
    style?: CSSProperties
}

const MobileTutorialProgress: React.FC<IMobileTutorialProgressProps> = (props: IMobileTutorialProgressProps) =>  {
    const circlesBarSource = require(`../../../assets/img/tutorial/circles-bar-${props.step}.svg`).default;

    return(
        <img src={circlesBarSource} style={props.style} alt="Mobile tutorial progress" />
    )
}

export default MobileTutorialProgress;