import { Box, Button, Dialog, FormControl, FormControlLabel, Grid, Input, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField, Theme, Typography, createStyles, makeStyles } from "@material-ui/core"
import { useState } from "react"

const styles = makeStyles({
    confirmButton: {
        color: 'white',
        textTransform: 'none',
        border: '1px solid rgba(0, 0, 0, 1)',
        borderRadius: '15px',
        background: 'black',
        minWidth: '200px',
        '&:hover': {
            background: 'rgba(235, 176, 76, 1)',
            color: 'black'
        },
        '&:disabled': {
            background: 'rgba(129, 129, 129, 1)',
            color: 'black'
        },
        '&:focus': {
            background: 'rgba(218, 93, 62, 1)',
            color: 'black'
        }
    },
    cancelButton: {
        color: 'rgba(218, 93, 62, 1)',
        textTransform: 'none',
        minWidth: '200px',
        borderRadius: '15px'
    },
    select: {
        minWidth: '200px',
    }
})

export const EndLendingPeriodDialog = ({ open, setOpen, confirmFallback, cancelFallback }: { open: boolean, setOpen: (open: boolean) => void, confirmFallback: any, cancelFallback: any }) => {
    const useStyles = styles();
    const [wasDamaged, setWasDamaged] = useState<boolean>(false);
    const [comment, setComment] = useState<string>('');

    const handleWasDamangedChanged = (event: React.ChangeEvent<{ value: unknown }>) => {
        setWasDamaged(event.target.value as boolean);
    }

    return (
        <Dialog open={open} onClose={() => setOpen(false)}>
            <Grid container spacing={4} style={{
                textAlign: 'center',
                marginTop: '1em',
                marginBottom: '1em'
            }}>
                <Grid item xs={12}>
                    <Typography variant="h6">
                        L'oggetto è rovinato?
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <InputRadioGroup handleChange={handleWasDamangedChanged} />
                </Grid>
                <Grid item xs={12}>
                    <Box width={'100%'}>
                        <Button className={useStyles.confirmButton} onClick={() => confirmFallback(comment, !wasDamaged)}>Concludi</Button>
                    </Box>
                    <Box width={'100%'}>
                        <Button className={useStyles.cancelButton} onClick={cancelFallback}>Annulla</Button>
                    </Box>
                </Grid>
            </Grid>
        </Dialog>
    )
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            border: '1px solid rgba(24, 27, 29, 1)',
            borderRadius: '10px',
            padding: 4,
            width: '60%',
            '@media (max-width: 600px)': {
                top: '16px'
            }
        },

    }),
);

const CommentInputField = ({ handleChange }: { handleChange: (event: React.ChangeEvent<{ value: unknown }>) => void }) => {
    const classes = useStyles();

    return (
        <>
            <FormControl className={classes.formControl} >
                <Input
                    style={{ zIndex: 10 }}
                    inputProps={{ disableUnderline: true }}
                    onChange={handleChange}
                    placeholder='Aggiungi un commento'
                    disableUnderline={true}
                    id="input-with-icon-adornment"
                />
            </FormControl>
        </>
    )
}

const InputRadioGroup = ({ handleChange }: { handleChange: (event: React.ChangeEvent<{ value: unknown }>) => void }) => {
    return (
        <FormControl>
            <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                onChange={handleChange}
                name="row-radio-buttons-group"
            >
                <Grid container direction="row" spacing={6}>
                    <Grid item xs={6}>
                        <FormControlLabel value={'true'} control={<Radio />} label="Si" />
                    </Grid>
                    <Grid item xs={6}>
                        <FormControlLabel value={'false'} control={<Radio />} label="No" />
                    </Grid>
                </Grid>
            </RadioGroup>
        </FormControl>
    );
}