import { Item } from "./libofthings.type"

export type RangeOfDates = {
    startDate: number,
    endDate: number
}

export type Preorder = {
    lender: string,
    borrower: string,
    preorderID: string,
    itemID: number,
    startDate: number,
    endDate: number,
    status: string,
    timestamp: number,
    contractAddress: string,
    reason?: string,
    itemWasFine?: boolean,
    comment?: string
}

export type QrContentForEndLendingPeriod = {
    handle: 'endLendingPeriod',
    preorder: Preorder,
    item: Item
}

export type QrCodeContent = {
    handle: 'preorderItem',
    item: Item,
    startDate: number,
    endDate: number
}

export type QrContentForStartLendingPeriod = {
    handle: 'startLendingPeriod',
    preorder: Preorder,
    item: Item
}

export enum PreorderStatus { 'Pending', 'Accepted', 'Started', 'Ended', 'Cancelled', 'Denied' }
export const PreorderStatuses = {
    Pending: 'Pending',
    Accepted: 'Accepted',
    Started: 'Started',
    Ended: 'Ended',
    Cancelled: 'Cancelled',
    Denied: 'Denied'
}
export const PreorderStatusStrings = ['Pending', 'Accepted', 'Started', 'Ended', 'Cancelled', 'Denied']