import { useContext, useEffect, useState } from "react";
import { Item } from "../../../types/libofthings.type";
import { LibraryOfThingsContractContext } from "../../../providers/LibraryOfThingsContractProvider";
import { Box, FormControl, Grid, InputLabel, MenuItem, Select, useMediaQuery } from "@material-ui/core";
import { Title } from "../BasicComponents/Title";
import { ListOfCards } from "../NewArrivals/ListOfCards";
import { useAppSelector } from "../../../store/hooks";

export const ItemsInHome = () => {
    const [items, setItems] = useState<Item[]>([]);
    const { getItems } = useContext(LibraryOfThingsContractContext);
    const categories = useAppSelector(state => state.libOfThings.categories);
    const [category, setCategory] = useState<string>(categories[0]);
    const isMobile = useMediaQuery('(max-width:600px)');

    useEffect(() => {
        setCategory(categories[0])
    }, [categories])

    useEffect(() => {
        (async () => {
            await getItemsByCategory();
        })();
    }, [category]);

    const getItemsByCategory = async () => {
        const items = await getItems(category, 0);
        if (items.items != null) {
            setItems(items.items);
        }
    }

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setCategory(event.target.value as string);
    }
    return (
        <Box marginBottom={'10em'}>
            <Grid container spacing={2} style={{paddingTop: isMobile ? '0' : '3rem'}}>
                <Grid item xs={12}>
                    <Title title={'Biblioteca delle cose'} />
                </Grid>
                <Grid item xs={2}>
                    <FormControl fullWidth>
                        <InputLabel id="category-select-label">Categoria</InputLabel>
                        <Select
                            labelId="category-label"
                            id="category-select"
                            value={category}
                            label="Categoria"
                            onChange={handleChange}
                            style={{
                                minWidth: '150px'
                            }}
                        >
                            {
                                categories.map((category, index) => {
                                    return <MenuItem key={index} value={category}>{category}</MenuItem>
                                })
                            }
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <ListOfCards items={items} />
                </Grid>
            </Grid>
        </Box>
    );
}