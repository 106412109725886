/* App Custom Theme */

import {createTheme} from '@material-ui/core/styles'
//Import some colors
import amber from '@material-ui/core/colors/amber';
import green from '@material-ui/core/colors/green'

const theme = createTheme({
    palette: {
        primary: {
            main: "#D45D3E"
        },
        secondary: {
            main: "#e65100"
        },
        info: {
            main: "#1565c0"
        },
        success: {
            main: green[700]
        },
        warning: {
            main: amber[700]
        },
        error: {
            main: "#f44336",
            light: "#fdd9d7"
        },
        type: "light"
    }
});

export default theme;
