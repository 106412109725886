import { createContext } from "react";
import axios from "../utilities/backend/axios-metadata";

export const ERC721ContractContext = createContext<{
    getCollectionsCreatedByAddress: (address: string) => Promise<any>;
}>({
    getCollectionsCreatedByAddress: async () => [],
});
export const ERC721ContractProvider = (props: any) => {
    const children = props.children;

    const getCollectionsCreatedByAddress = async (address: string) => {
        let collections = [];
        if (address) {
            const url = "/nft/getCollectionsCreated";
            const params = new URLSearchParams({
                owner: address,
            });
            const collectionList = await axios.get(url, { params });
            collections = collectionList.data.nfts;
        } else {
            console.log(`No address of logged user found`);
        }
        return collections;
    }

    const viewContextData = {
        getCollectionsCreatedByAddress
    };

    return <ERC721ContractContext.Provider value={viewContextData}> {children} </ERC721ContractContext.Provider>;
};
