import { makeStyles } from "@material-ui/core";
import { CSSProperties } from "react";

export type SeparatorDirection = "Horizontal" | "Vertical";


const defaultBorderColor = '#DA5D3E';



const styles = makeStyles({
    horizontal: {
        borderTop: `1px solid ${defaultBorderColor}`,
    },
    vertical: {
        borderLeft: `1px solid ${defaultBorderColor}`,
        height: '100px',
    }
});

interface CosoSeparatorProps {
    /**
     * Specifies the direction of the separator. If vertical, it defaults to an height of 100px if not specified
     */
    direction: SeparatorDirection;
    style?: CSSProperties;
    className?: string;
}


export const CosoSeparator: React.FC<CosoSeparatorProps> = ({ direction, style, className }) => {

    const lineStyle = styles();
    return (
        <div className={`${direction === 'Horizontal' ? lineStyle.horizontal : lineStyle.vertical} ${className}`} style={{ ...style }}>

        </div>
    )
};