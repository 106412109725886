import { Grid, Typography, makeStyles, useMediaQuery } from "@material-ui/core"
import { useContext, useEffect, useState } from "react";
import { Calendar } from "react-calendar"
import { useAppSelector, useAppDispatch } from "../../../store/hooks";
import { getDatesOfItem } from "../../../store/slices/calendarSlice";
import { calendarTileStyle } from "../Styles/Calenda.style";
import { Item } from "../../../types/libofthings.type";
import { CalendarContractContext } from "../../../providers/CalendarContractProvider";
import { useTranslation } from "react-i18next";

const calendarContainerStyle = makeStyles({
    root: {
        position: 'relative',
        background: 'white',
        borderRadius: '18px',
        padding: '1em',
        textAlign: 'center',
        height: '250px',
        fontSize: '14px',
    },
    confirmCircle: {
        width: '158px',
        height: '158px',
        ['@media (max-width: 600px)']: {
            width: '72px',
            height: '72px',
        }
    }
})

export const ItemCalendar = ({ item, summaryOpened }: {
    item: Item,
    summaryOpened: boolean
}) => {
    const dispatch = useAppDispatch();
    const useCalendarTileStyle = calendarTileStyle();
    const useCalendarContainerStyle = calendarContainerStyle();
    const dates = useAppSelector(state => state.calendar.rangeOfDatesForItem);
    const { setDates, startDate, endDate } = useContext(CalendarContractContext);
    const classes = calendarContainerStyle();
    const isMobile = useMediaQuery('(max-width:600px)');

    useEffect(() => {
        (() => dispatch(getDatesOfItem(item.itemID)))()
    }, [])

    const disableRangeOfDates = (date: Date) => {
        const yesterdatDate = new Date();
        yesterdatDate.setDate(yesterdatDate.getDate() - 1);
        return isWithinRange(date.getTime() / 1000) || date.getTime() <= yesterdatDate.getTime();
    }

    const isWithinRange = (selectedDate: number): boolean => {
        let rangeFound = false;

        for (let i = 0; i < dates.length && !rangeFound; i++) {
            if (selectedDate >= dates[i].startDate && selectedDate <= dates[i].endDate) {
                rangeFound = true;
            }
        }
        return rangeFound;
    }

    const getSelectedLanguage = () => {
        const language = localStorage.getItem('i18nextLng');
        if (language == null)
            return 'en';
        if (language.includes('en'))
            return 'en'
        else
            return 'it'
    }

    useEffect(() => {
        highlightTodayInCalendar();
    }, [])

    const highlightTodayInCalendar = () => {
        var children = [].slice.call(document.getElementById('calendar-container')!.getElementsByTagName('button'), 0);
        const dayNumberInMonth = new Date().getDate();

        children.map((child: any) => {
            if (Number(child.innerText) === dayNumberInMonth) {
                if (!child.classList.contains('react-calendar__month-view__days__day--neighboringMonth')) {
                    child.style.color = 'rgba(218, 93, 62, 1)';
                    child.style.background = 'none';
                }
            }
        })
    }

    return (
        <>
            {
                !summaryOpened ? (
                    <Grid container className={`${useCalendarContainerStyle.root}`} >

                        <Grid item xs={12} id="calendar-container">
                            <Calendar
                                locale={getSelectedLanguage()}
                                onChange={(value: any) => {
                                    setDates(value[0], value[1]);
                                }}
                                defaultView={'month'}
                                maxDetail={'month'}
                                minDetail={'year'}
                                returnValue={'range'}
                                selectRange={true}
                                tileDisabled={({ date }) => disableRangeOfDates(date)}
                                tileClassName={useCalendarTileStyle.root}
                            ></Calendar>
                        </Grid>
                    </Grid>
                ) : (
                    <Grid container>
                        {
                            !isMobile && (
                                <Grid item xs={12} style={{ marginTop: '1.25rem' }}>
                                    <Typography>Richiesto il <b>{new Date()?.toLocaleDateString()}</b> per il periodo dal <b>{startDate?.toLocaleDateString()}</b> al <b>{endDate?.toLocaleDateString()}</b></Typography>
                                </Grid>
                            )
                        }
                        <Grid item xs={12}>
                            <Grid item xs={12} style={{ textAlign: 'center', marginTop: '2em' }}>
                                <img src="/svgs/confirmation_circle.svg" className={classes.confirmCircle}></img>
                            </Grid>
                            <Grid item xs={12} style={{ textAlign: 'center' }}>
                                <Typography variant="h5">Ottimo!</Typography>
                            </Grid>
                            <Grid container style={{ marginTop: '1em' }}>
                                <Grid item xs={2}></Grid>
                                <Grid item xs={8} style={{ textAlign: 'center' }}>
                                    <Typography variant="subtitle2">La tua richiesta di prestito è stata inviata con successo!</Typography>
                                </Grid>
                                <Grid item xs={2}></Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                )
            }
        </>
    )
}