import Logout from "../containers/Auth/Logout/Logout";
import Messages from "../components/NotificationsHistory/Messages";
import { LibOfThingsHome } from "../containers/LibOfThings/LibOfThingsHome";
import { ItemPage } from "../containers/LibOfThings/ItemPage/ItemPage";
import { MyInventory } from "../containers/LibOfThings/MyInventory/MyInventory";
import { MyRequestsContainer } from "../containers/LibOfThings/MyRequests/MyRequestsContainer";
import { UploadItemHome } from "../containers/LibOfThings/UploadItem/UploadItemHome";
import { WalletPage } from "../containers/Wallet/WalletPage";
import { TutorialMobile } from "../containers/LibOfThings/Tutorial/TutorialMobile";
import { TutorialDesktop } from "../containers/LibOfThings/Tutorial/TutorialDesktop";
import { UserPageWithItems } from "../containers/LibOfThings/UserPage/UserPageWithItems";
import { ItemsExplorerPage } from "../containers/LibOfThings/ItemsExplorer/ItemsExplorerPage";
import { CouponHomePage } from "../containers/Wallet/Coupon/CoupontHomePage";

const indexRoutes = [
    { path: "/messages", name: "Messages", component: Messages, private: true, exact: false },
    { path: "/logout", name: "Logout", component: Logout, private: true, exact: false },
    { path: "/libraryOfThings", name: "Library of things", component: LibOfThingsHome, private: true, exact: false },
    { path: "/items/upload", name: "Upload assets library of things", component: UploadItemHome, private: true, exact: false },
    { path: "/items/:collection/:itemID", name: "Item page for library of things", component: ItemPage, private: true, exact: false },
    { path: "/user/items", name: "List of owned items", component: MyInventory, private: true, exact: false },
    { path: "/user/requests", name: "List of preorders", component: MyRequestsContainer, private: true, exact: false },
    { path: "/user/wallet", name: "Wallet", component: WalletPage, private: true, exact: false },
    { path: "/", name: "Home", component: LibOfThingsHome, private: true, exact: true },
    { path: "/tutorial/mobile", name: "Mobile Tutorial", component: TutorialMobile, private: true, exact: false },
    { path: "/tutorial/desktop", name: "Desktop Tutorial", component: TutorialDesktop, private: true, exact: false },
    { path: "/users/:address/:email/items", name: "User Items", component: UserPageWithItems, private: true, exact: false },
    { path: "/items/search", name: "Items explorer", component: ItemsExplorerPage, private: true, exact: false },
    { path: "/coupon/home", name: "Coupont homepage", component: CouponHomePage, private: true, exact: true }
];

export default indexRoutes;
