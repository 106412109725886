import {
    Button,
    Dialog,
    DialogTitle,
    Grid,
    TextField,
} from "@material-ui/core";
import { BlackButton } from "../../../../../components/Buttons/BlackButton";
import { useState } from "react";


export const DenyDialog = (props: {
    open: boolean;
    onClose: () => void;
    denyAction: (reason: string) => void;
}) => {
    const { onClose, open, denyAction } = props;
    const [reason, setReason] = useState('')

    const handleClose = () => {
        onClose();
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="deny-lending-dialog"
        >
            <DialogTitle id="deny-dialog-title">
                Spiega il motivo per cui vuoi rifiutare la richiesta
            </DialogTitle>

            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
            >
                <Grid
                    container
                    item
                    style={{ paddingLeft: "1rem", paddingRight: "1rem" }}
                >
                    <TextField
                        multiline
                        fullWidth
                        rows={5}
                        variant="outlined"
                        placeholder="Aggiungi il motivo qua"
                        value={reason}
                        onChange={(event) => setReason(event.target.value) }
                    />
                </Grid>

                <Grid item style={{ marginTop: "2rem" }}>
                    <BlackButton
                        onClick={() => denyAction(reason)}
                        text={"Invia"}
                        style={{
                            width: "10.5rem",
                            height: "auto",
                            color: "white",
                        }}
                        disabled={reason === ''}
                    />
                </Grid>
                <Grid
                    item
                    style={{ marginTop: "0.5rem", marginBottom: "1rem" }}
                >
                    <Button
                        onClick={onClose}
                        style={{
                            width: "10.5rem",
                            height: "auto",
                            textTransform: "capitalize",
                        }}
                        color="secondary"
                    >
                        Annulla
                    </Button>
                </Grid>
            </Grid>
        </Dialog>
    );
};
