import { Box, Grid, makeStyles } from "@material-ui/core";
import { AccountAction } from "../../LibOfThings/MyAccount/AccountActions";
import { useHistory } from "react-router-dom";
import { UploadItemTitle } from "../../LibOfThings/UploadItem/UploadItemTitle";
import { CouponActionsHome } from "./CouponActionsHome";


const useStyles = makeStyles({
})

export const CouponHomePage = () => {
    const classes = useStyles();
    const history = useHistory();
    
    return (
        <Box>
            <Grid container item xs={12} onClick={() => history.push('/')} style={{
                cursor: 'pointer',
                marginTop: '1em'
            }}>
                <UploadItemTitle text={'I miei Coupon'} />
            </Grid>
            <CouponActionsHome />
        </Box>
    );
}