import { Box, Button, Grid, TextField, Typography, makeStyles, useMediaQuery } from "@material-ui/core";
import { ERC20BalanceContainer } from "./WalletPageContent/ERC20BalanceContainer";
import { useContext, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { coinSend, getTokenFromSymbolSlice } from "../../store/slices/coinSlice";
import { UserData } from "../../types/user.type";
import { getUsersList } from "../../api/userAPI";
import toast from "react-hot-toast";
import { SelectTokenQuantity } from "./WalletPageContent/SelectTokenQuantity";
import { CustomToaster } from "../Notifications/CustomToaster";
import { getTokenWithBalanceFromMetadata } from "../../api/coinAPI";
import { Token } from "../../types/coin.type";
import { TutorialFab } from "../LibOfThings/Tutorial/TutorialFab";
import { TutorialContext } from "../../providers/TutorialProvider";
import { useHistory } from "react-router-dom";
import config from "../../config";

const styles = makeStyles({
    sendButton: {
        border: '1px solid rgba(0, 0, 0, 1)',
        width: '100%'
    }
})

export const WalletPage = () => {
    const [receiver, setReceiver] = useState<string>('');
    const dispatch = useAppDispatch();
    const [contacts, setContacts] = useState<UserData[]>();
    const [amountToSend, setAmountToSend] = useState(0);
    const [erc20Info, setErc20Info] = useState<Token>();
    const classes = styles();
    const loading = useAppSelector(state => state.coin.coinSendLoading);
    const user = useAppSelector((state) => state.user.currentProfile);
    const isMobile = useMediaQuery('(max-width:600px)');
    const { openTutorialDesktop, openTutorialMobile } = useContext(TutorialContext);
    const history = useHistory();

    useEffect(() => {
        (async () => {
            const contacts = await getUsersList();
            setContacts(contacts);
        })()
    }, [])

    useEffect(() => {
        (async () => {
            const tokenAddr = await dispatch(getTokenFromSymbolSlice(config.project.tokenSymbol));
            if (tokenAddr != null && user?.additional_properties?.commonshoodWallet != null) {
                const balance = await getTokenWithBalanceFromMetadata(user?.additional_properties?.commonshoodWallet, tokenAddr.contractAddress, config.project.tokenSymbol);
                setErc20Info(balance[0]);
            }
        })()
    }, [])

    const handleChangeReceiver = (event: React.ChangeEvent<HTMLInputElement>) => {
        setReceiver(event.target.value);
    }

    const sendCoinsFallback = () => {
        const toastId = toast.loading('Invio in corso...');
        try {
            const recipientFiltered = contacts?.find(contact => contact.email === receiver);
            if (!recipientFiltered) {
                toast.error('Utente non trovato');
                return;
            }
            if (amountToSend <= 0) {
                toast.error('Inserisci una quantità positiva');
                return;
            }
            if (amountToSend >= Number(erc20Info?.balance)) {
                toast.error(`Non hai abbastanza ${config.project.tokenSymbol}`);
                return;
            }
            toast.promise(
                dispatch(coinSend({
                    recipient: recipientFiltered,
                    amount: amountToSend.toString(),
                    symbol: config.project.tokenSymbol,
                    decimals: 2
                })),
                {
                    loading: 'Invio in corso...',
                    success: 'Invio completato!',
                    error: 'Invio fallito'
                }
            );
        } catch (e: any) {
            console.log(e.message);
            toast.error(e.message);
        } finally {
            toast.dismiss(toastId);
        }
    }

    const setAmountFallback = (amount: number) => {
        setAmountToSend(amount);
    }

    const tutorialFabCallback = () => {
        if (isMobile)
            openTutorialMobile();
        else
            openTutorialDesktop();
    }

    return (
        <>
            <CustomToaster />
            <TutorialFab callback={tutorialFabCallback} />
            <Box margin={1}>
                <Grid container direction="column" spacing={4}>
                    <Grid item xs={12}>
                        <Typography variant="h6">A chi vuoi inviare?</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField id="outlined-basic" label="Email del ricevente" variant="outlined" onChange={handleChangeReceiver} />
                    </Grid>
                    <Grid item xs={12}>
                        <SelectTokenQuantity fallback={setAmountFallback} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Button className={classes.sendButton} onClick={sendCoinsFallback} disabled={loading}>Invia</Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6">Il mio portafoglio</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                        <ERC20BalanceContainer erc20Info={erc20Info} />
                    </Grid>
                </Grid>
            </Box>
        </>
    );
}