import { useContext, useState } from "react";
import { TutorialContext } from "../../../providers/TutorialProvider";
import { Grid } from "@material-ui/core";

export const TutorialDesktop = () => {
    const { closeTutorial } = useContext(TutorialContext);
    const [step, setStep] = useState<number>(1);

    const nextStep = () => {
        if (step < 3)
            setStep(step + 1);
        else
            closeTutorial();
    }

    const previousStep = () => {
        if (step > 1)
            setStep(step - 1);
    }

    return (
        <>
            <img
                onClick={closeTutorial}
                src="/images/x.png"
                style={{
                    width: '25px',
                    height: '25px',
                    position: 'absolute',
                    zIndex: 1102,
                    top: '10px',
                    right: '10px',
                    cursor: 'pointer'
                }}
            />
            <div style={{
                width: '100%',
                height: '100%',
                position: 'absolute',
                zIndex: 1100,
                top: '0',
                left: '0',
                backgroundColor: '#2f3133'
            }} >
            </div>
            <img src={`/images/tutorial_desk_${step}.png`} style={{
                width: 'auto',
                height: '100%',
                position: 'absolute',
                zIndex: 1101,
                top: '0',
                left: '50%',
                transform: 'translateX(-50%)'
            }} />
            <Grid container style={{
                bottom: '-78px',
                position: 'absolute',
            }}>
                <Grid item xs={4} style={{
                    textAlign: 'right'
                }}>
                    {
                        step > 1 && <img src="/svgs/previous_step.svg" style={{
                            width: '100px',
                            height: '200px',
                            position: 'absolute',
                            zIndex: 1103,
                            bottom: '25px',
                            cursor: 'pointer'
                        }} onClick={previousStep} />
                    }
                </Grid>
                <Grid item xs={3}></Grid>
                <Grid item xs={5}>
                    <img src="/svgs/next_step.svg" style={{
                        width: '100px',
                        height: '200px',
                        position: 'absolute',
                        zIndex: 1103,
                        bottom: '25px',
                        cursor: 'pointer'
                    }} onClick={nextStep}
                    />
                </Grid>
            </Grid>

        </>
    );
}