import { Badge, Box, makeStyles, Tab, Tabs, Typography, useMediaQuery } from "@material-ui/core"
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { styled } from '@material-ui/core/styles';
import { CalendarContractContext } from "../../../providers/CalendarContractProvider";
import { LibraryOfThingsContractContext } from "../../../providers/LibraryOfThingsContractProvider"
import { NumberOfPreordersAsLender } from "../../../types/libofthings.type";
import { useAppSelector } from "../../../store/hooks";

const style = makeStyles({
    box: {
        width: '100%',
    },
    'mx-auto': {
        margin: '0 auto',
    }
})

export const MyInventoryTabs = ({ handleMenuChange }: {
    handleMenuChange: (newMenu: number) => void
}) => {
    const [value, setValue] = useState(0);
    const useStyles = style();
    const { t } = useTranslation('LibOfThings');
    const isMobile = useMediaQuery('(max-width: 600px)');
    const { statsLender: stats, getUserStats } = useContext(CalendarContractContext);
    const account = useAppSelector(state => state.user.currentProfile);
    const { getUserItems, numberOfItemsPerPage } = useContext(LibraryOfThingsContractContext);
    const [numberOfItems, setNumberOfItems] = useState<number>(0);

    
    const getItems = async () => {
        const address = account?.additional_properties?.commonshoodWallet;
        if (address != null) {
            const items = await getUserItems(address, 0);
            setNumberOfItems(items.numberOfItems);
        }
    }

    const tabTitleMap = new Map<number, string>([
        [0, 'Tutti i miei oggetti'],
        [1, 'In attesa'],
        [2, 'In prestito'],
        [3, 'Completate'],
        [4, 'Rifiutate']
    ])

    useEffect(() => {
        (async () => {
            await getUserStats();
            await getItems();
        })()
    }, []);

    const handleChange = (event: any, newValue: number) => {
        setValue(newValue);
        handleMenuChange(newValue);
    };

    return (
        <Box style={{ borderColor: 'divider' }} className={useStyles.box}>
            {
                (isMobile && stats != null) ? (
                    <>
                        <Tabs value={value} style={{ padding: '16px' }} onChange={handleChange} aria-label="basic tabs example" centered>
                            <CustomTab icon={
                                <Badge badgeContent={0} color={'secondary'} max={99}>
                                    <img src="/svgs/my_items.svg"></img>
                                </Badge>
                            } />
                            <CustomTab icon={
                                <Badge badgeContent={stats.pending} color={'secondary'} showZero max={99}>
                                    <img src="/svgs/pending_logo.svg"></img>
                                </Badge>
                            } />
                            <CustomTab icon={
                                <Badge badgeContent={stats.started + stats.accepted} showZero max={99}>
                                    <img src="/svgs/active_request.svg"></img>
                                </Badge>
                            } />
                            <CustomTab icon={
                                <Badge badgeContent={stats.ended} color={'secondary'} showZero max={99}>
                                    <img src="/svgs/request_complete.svg"></img>
                                </Badge>
                            } />
                            <CustomTab icon={
                                <Badge badgeContent={stats.canceled} color={'secondary'} showZero max={99}>
                                    <img src="/svgs/request_denied.svg"></img>
                                </Badge>
                            } />
                        </Tabs>
                        <Typography style={{ textAlign: 'center' }}>{tabTitleMap.get(value)}</Typography>
                    </>
                ) : (
                    <>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" variant="fullWidth">
                            <CustomTab label={`Tutti${numberOfItems > 0 ? ` (${numberOfItems})`: ''}`} />
                            <CustomTab label={`${t('pending')}${stats !== undefined && stats.pending > 0 ? ` (${stats.pending})`: ''}`} />
                            <CustomTab label={`${t('lended')}${stats !== undefined && stats.started + stats.accepted > 0 ? ` (${stats.started + stats.accepted})`: ''}`} />
                            <CustomTab label={`${t('completed')}${stats !== undefined && stats.ended > 0 ? ` (${stats.ended})`: ''}`} />
                            <CustomTab label={`${t('denied')}${stats !== undefined && stats.canceled > 0 ? ` (${stats.canceled})`: ''}`} />
                        </Tabs>
                    </>
                )
            }
        </Box>
    )
}

const CustomTab = styled(Tab)({
    textTransform: 'none',
    width: '20%',
    borderBottom: '1px solid black',
    '& .MuiBadge-badge': {
        background: 'black',
        color: 'white',
        marginTop: '5px',
    }
})