import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

import {
  acceptExchange,
  cancelExchange,
  createExchange,
  refillExchange,
  renewExchange,
  getExchanges,
} from "../../api/exchangeAPI";
import { getTokenFromMetadata } from "../../api/coinAPI";

import { calculateAvatar } from "../../utilities/utilities";
import {
  assetDecimalRepresentationToInteger,
  assetIntegerToDecimalRepresentation,
} from "./../../utilities/decimalsHandler/decimalsHandler";
import { logger } from "../../utilities/logger/logger";

import { getUserNameByAddress, UserNameType } from "../../api/userAPI";
import { getIpfsUrl } from "../../utilities/ipfs/ipfs";
import { Exchange } from "../../types/exchange.type";

type ExchangeInitialState = {
  exchangeCreated: boolean;
  loading: boolean;
  error: string | null;
  exchanges: Exchange[];
  expiredExchanges: Exchange[];
  exchangeAccepted: boolean;
  exchangeCancelled: boolean;
  exchangeRenewed: boolean;
  exchangeRefilled: boolean;
  exchangeModified: boolean;
  onGoingOperations: string[];
  paginationHandler: boolean;
};

const initialState: ExchangeInitialState = {
  exchangeCreated: false,
  loading: false,
  error: null,
  exchanges: [],
  expiredExchanges: [],
  exchangeAccepted: false,
  exchangeCancelled: false,
  exchangeRenewed: false,
  exchangeRefilled: false,
  exchangeModified: false,
  onGoingOperations: [],
  paginationHandler: false,
};

export const exchangeSlice = createSlice({
  name: "exchange",
  initialState,
  reducers: {
    exchangeCreateStart(state) {
      state.loading = true;
      state.exchangeCreated = false;
      state.error = null;
    },

    exchangeCreateReset(state) {
      state.loading = false;
      state.exchangeCreated = false;
    },

    exchangeCreateFail(state, action: PayloadAction<{ error: string }>) {
      state.loading = false;
      state.exchangeCreated = false;
      state.error = action.payload.error;
    },

    exchangeCreateSuccess(state) {
      state.loading = false;
      state.exchangeCreated = true;
    },

    exchangeGetAllReset(state) {
      state.loading = false;
      state.exchanges = [];
    },

    exchangeGetAllStart(state) {
      state.loading = true;
    },

    exchangeGetAllSuccess(
      state,
      action: PayloadAction<{
        exchangesArray: any[];
        expiredExchangesArray: any[];
      }>
    ) {
      state.loading = false;
      state.exchanges = action.payload.exchangesArray;
      state.expiredExchanges = action.payload.expiredExchangesArray;
    },

    exchangeGetAllFail(state, action: PayloadAction<{ error: string }>) {
      state.loading = false;
      state.error = action.payload.error;
      state.exchanges = [];
    },
    exchangePaginationReset(state) {
      state.loading = false;
      state.exchanges = [];
      state.expiredExchanges = [];
    },

    exchangePaginationStart(state) {
      state.loading = true;
    },

    exchangePaginationAdd(state, action: PayloadAction<{ exchanges: any[] }>) {
      for (let exc in action.payload.exchanges)
        state.exchanges.push(action.payload.exchanges[exc]);
    },
    expiredExchangePaginationAdd(
      state,
      action: PayloadAction<{ expiredExchanges: any[] }>
    ) {
      for (let exc in action.payload.expiredExchanges)
        state.expiredExchanges.push(action.payload.expiredExchanges[exc]);
    },
    exchangePaginationSuccess(state) {
      state.loading = false;
    },

    exchangePaginationFail(state, action: PayloadAction<{ error: string }>) {
      state.loading = false;
      state.error = action.payload.error;
      state.exchanges = [];
    },

    exchangeAcceptStart(state, action: PayloadAction<{ exchange: string }>) {
      state.onGoingOperations.push(action.payload.exchange);
      state.exchangeAccepted = false;
    },

    exchangeAcceptSuccess(state, action: PayloadAction<{ exchange: string }>) {
      const exchange = action.payload.exchange;
      state.onGoingOperations = state.onGoingOperations.filter(
        (item) => item !== exchange
      );
      state.exchangeAccepted = true;
    },

    exchangeAcceptFail(
      state,
      action: PayloadAction<{ exchange: string; error: string }>
    ) {
      const exchange = action.payload.exchange;
      state.onGoingOperations = state.onGoingOperations.filter(
        (item) => item !== exchange
      );
      state.exchangeAccepted = false;
      state.error = action.payload.error;
    },

    exchangeCancelStart(state, action: PayloadAction<{ exchange: string }>) {
      state.onGoingOperations.push(action.payload.exchange);
      state.exchangeCancelled = false;
    },

    exchangeCancelSuccess(state, action: PayloadAction<{ exchange: string }>) {
      const exchange = action.payload.exchange;
      state.onGoingOperations = state.onGoingOperations.filter(
        (item) => item !== exchange
      );
      state.exchangeCancelled = true;
    },

    exchangeCancelFail(
      state,
      action: PayloadAction<{ exchange: string; error: string }>
    ) {
      const exchange = action.payload.exchange;
      state.onGoingOperations = state.onGoingOperations.filter(
        (item) => item !== exchange
      );
      state.exchangeCancelled = false;
      state.error = action.payload.error;
    },

    exchangeRenewStart(state, action: PayloadAction<{ exchange: string }>) {
      state.onGoingOperations.push(action.payload.exchange);
      state.exchangeRenewed = false;
    },

    exchangeRenewSuccess(state, action: PayloadAction<{ exchange: string }>) {
      const exchange = action.payload.exchange;
      state.onGoingOperations = state.onGoingOperations.filter(
        (item) => item !== exchange
      );
      state.exchangeRenewed = true;
    },

    exchangeRenewFail(
      state,
      action: PayloadAction<{ exchange: string; error: string }>
    ) {
      const exchange = action.payload.exchange;
      state.onGoingOperations = state.onGoingOperations.filter(
        (item) => item !== exchange
      );
      state.exchangeRenewed = false;
      state.error = action.payload.error;
    },

    exchangeRefillStart(state, action: PayloadAction<{ exchange: string }>) {
      state.onGoingOperations.push(action.payload.exchange);
      state.exchangeRefilled = false;
    },

    exchangeRefillSuccess(state, action: PayloadAction<{ exchange: string }>) {
      const exchange = action.payload.exchange;
      state.onGoingOperations = state.onGoingOperations.filter(
        (item) => item !== exchange
      );
      state.exchangeRefilled = true;
    },

    exchangeRefillFail(
      state,
      action: PayloadAction<{ exchange: string; error: string }>
    ) {
      const exchange = action.payload.exchange;
      state.onGoingOperations = state.onGoingOperations.filter(
        (item) => item !== exchange
      );
      state.exchangeRefilled = false;
      state.error = action.payload.error;
    },

    exchangeModifyStart(state, action: PayloadAction<{ exchange: string }>) {
      state.onGoingOperations.push(action.payload.exchange);
      state.exchangeModified = false;
    },

    exchangeModifySuccess(state, action: PayloadAction<{ exchange: string }>) {
      const exchange = action.payload.exchange;
      state.onGoingOperations = state.onGoingOperations.filter(
        (item) => item !== exchange
      );
      state.exchangeModified = true;
    },

    exchangeModifyFail(
      state,
      action: PayloadAction<{ exchange: string; error: string }>
    ) {
      const exchange = action.payload.exchange;
      state.onGoingOperations = state.onGoingOperations.filter(
        (item) => item !== exchange
      );
      state.exchangeModified = false;
      state.error = action.payload.error;
    },
  },
});

export const {
  exchangeCreateStart,
  exchangeCreateReset,
  exchangeCreateFail,
  exchangeCreateSuccess,
  exchangeGetAllStart,
  exchangeGetAllReset,
  exchangeGetAllFail,
  exchangeGetAllSuccess,
  exchangeAcceptStart,
  exchangeAcceptSuccess,
  exchangeAcceptFail,
  exchangeCancelStart,
  exchangeCancelSuccess,
  exchangeCancelFail,
  exchangeRenewStart,
  exchangeRenewSuccess,
  exchangeRenewFail,
  exchangeRefillStart,
  exchangeRefillSuccess,
  exchangeRefillFail,
  exchangeModifyStart,
  exchangeModifySuccess,
  exchangeModifyFail,
  exchangePaginationFail,
  exchangePaginationReset,
  exchangePaginationStart,
  exchangePaginationSuccess,
  exchangePaginationAdd,
  expiredExchangePaginationAdd,
} = exchangeSlice.actions;

export const exchangeCreate = (
  coinsOfferedAddr: string[],
  amountsOffered: number[],
  coinsRequiredAddr: string[],
  amountsRequired: number[],
  repeatings: number,
  selectedExpirationDate: number
) => {
  logger.info("[IN EXCHANGECREATE action]");
  return async (dispatch: Dispatch, getState: () => RootState) => {
    dispatch(exchangeCreateStart());

    const ethers = getState().ethers.ethersInstance;
    const currentProfile = getState().user.currentProfile;
    if (!currentProfile || !ethers) {

      dispatch(
        exchangeCreateFail({
          error: `Something went wrong, current profile is undefined! ${currentProfile}`,
        })
      );
      return;
    }

    try {
      let accountAddress: string | undefined;

      accountAddress = currentProfile.additional_properties?.commonshoodWallet;

      if (accountAddress == null) {

        dispatch(
          exchangeCreateFail({
            error: `Something went wrong, wallet address of current profile is undefined! ${currentProfile}`,
          })
        );
        return;
      }

      const trueAmountsOffered = [];
      const trueAmountsRequired = [];

      for (let i = 0; i < amountsOffered.length; i++) {
        const coinData = await getTokenFromMetadata(coinsOfferedAddr[i], "");
        const decimals = coinData.decimals;
        trueAmountsOffered.push(
          parseInt(
            assetDecimalRepresentationToInteger(amountsOffered[i], decimals)
          )
        );
      }

      for (let i = 0; i < amountsRequired.length; i++) {
        const coinData = await getTokenFromMetadata(coinsRequiredAddr[i], "");
        const decimals = coinData.decimals;
        trueAmountsRequired.push(
          parseInt(
            assetDecimalRepresentationToInteger(amountsRequired[i], decimals)
          )
        );
      }

      await createExchange(
        ethers,
        accountAddress,
        coinsOfferedAddr,
        trueAmountsOffered,
        coinsRequiredAddr,
        trueAmountsRequired,
        repeatings,
        selectedExpirationDate
      );


      dispatch(exchangeCreateSuccess());
    } catch (error: any) {
      logger.debug("Error =>", error);
      const errorMessage = error?.message
        ? error.message
        : "ERROR -> Something went wrong while retriving exchanges";

      dispatch(exchangeCreateFail({ error: errorMessage }));
    }
  };
};

export const exchangeAccept = (
  exchangeAddress: string,
  coinsRequired: any[],
  repeatings: number
) => {
  logger.info("[IN EXCHANGEACCEPT action]");

  return async (dispatch: Dispatch, getState: () => RootState) => {
    dispatch(exchangeAcceptStart({ exchange: exchangeAddress }));
    const ethers = getState().ethers.ethersInstance;
    const currentProfile = getState().user.currentProfile;

    if (!currentProfile || !ethers) {

      dispatch(
        exchangeAcceptFail({
          exchange: exchangeAddress,
          error: `Something went wrong, current profile is undefined! ${currentProfile}`,
        })
      );
      return;
    }

    try {
      let accountAddress: string | undefined;
      accountAddress = currentProfile.additional_properties?.commonshoodWallet;

      if (accountAddress == null) {

        dispatch(
          exchangeAcceptFail({
            exchange: exchangeAddress,
            error: `Something went wrong, wallet address of current profile is undefined! ${currentProfile}`,
          })
        );
        return;
      }

      const coinsRequiredAddr = [];
      const trueAmountsRequired = [];

      for (let coin of coinsRequired) {
        coinsRequiredAddr.push(coin.address);
        trueAmountsRequired.push(
          parseInt(
            assetDecimalRepresentationToInteger(coin.amount, coin.decimals)
          )
        );
      }

      await acceptExchange(
        ethers,
        accountAddress,
        exchangeAddress,
        coinsRequiredAddr,
        trueAmountsRequired,
        repeatings
      );

      dispatch(exchangeAcceptSuccess({ exchange: exchangeAddress }));
    } catch (error: any) {
      logger.debug("Error =>", error);
      const errorMessage = error?.message
        ? error.message
        : "ERROR -> Something went wrong while accepting exchange";

      dispatch(
        exchangeAcceptFail({ exchange: exchangeAddress, error: errorMessage })
      );
    }
  };
};

export const exchangeCancel = (exchangeAddress: string) => {
  logger.info("[IN EXCHANGECANCEL action]");

  return async (dispatch: Dispatch, getState: () => RootState) => {
    dispatch(exchangeCancelStart({ exchange: exchangeAddress }));

    const ethers = getState().ethers.ethersInstance;
    const currentProfile = getState().user.currentProfile;
    if (!currentProfile || !ethers) {
      dispatch(
        exchangeCancelFail({
          exchange: exchangeAddress,
          error: `Something went wrong, current profile is undefined! ${currentProfile}`,
        })
      );
      return;
    }

    try {
      let accountAddress: string | undefined;
      accountAddress = currentProfile.additional_properties?.commonshoodWallet;

      if (accountAddress == null) {
        dispatch(
          exchangeCancelFail({
            exchange: exchangeAddress,
            error: `Something went wrong, wallet address of current profile is undefined! ${currentProfile}`,
          })
        );
        return;
      }

      await cancelExchange(ethers, accountAddress, exchangeAddress);

      dispatch(exchangeCancelSuccess({ exchange: exchangeAddress }));
    } catch (error: any) {
      logger.debug("Error =>", error);
      const errorMessage = error?.message
        ? error.message
        : "ERROR -> Something went wrong while cancelling exchange";
      dispatch(
        exchangeCancelFail({ exchange: exchangeAddress, error: errorMessage })
      );
    }
  };
};

export const exchangeRenew = (exchangeAddress: string, expiration: number) => {
  logger.info("[IN EXCHANGE RENEW action]");

  return async (dispatch: Dispatch, getState: () => RootState) => {
    dispatch(exchangeRenewStart({ exchange: exchangeAddress }));

    const ethers = getState().ethers.ethersInstance;
    const currentProfile = getState().user.currentProfile;
    if (!currentProfile || !ethers) {
      dispatch(
        exchangeRenewFail({
          exchange: exchangeAddress,
          error: `Something went wrong, current profile is undefined! ${currentProfile}`,
        })
      );
      return;
    }

    try {
      let accountAddress: string | undefined;
      accountAddress = currentProfile.additional_properties?.commonshoodWallet;

      if (accountAddress == null) {
        dispatch(
          exchangeRenewFail({
            exchange: exchangeAddress,
            error: `Something went wrong, wallet address of current profile is undefined! ${currentProfile}`,
          })
        );
        return;
      }

      await renewExchange(ethers, accountAddress, exchangeAddress, expiration);

      dispatch(exchangeRenewSuccess({ exchange: exchangeAddress }));
    } catch (error: any) {
      logger.debug("Error =>", error);
      const errorMessage = error?.message
        ? error.message
        : "ERROR -> Something went wrong while retriving exchanges";
      dispatch(
        exchangeRenewFail({ exchange: exchangeAddress, error: errorMessage })
      );
    }
  };
};

export const exchangeRefill = (
  exchangeAddress: string,
  coinsOffered: any[],
  repeatings: number
) => {
  logger.info("[IN EXCHANGE REFILL action]");

  return async (dispatch: Dispatch, getState: () => RootState) => {
    dispatch(exchangeRefillStart({ exchange: exchangeAddress }));
    const ethers = getState().ethers.ethersInstance;
    const currentProfile = getState().user.currentProfile;
    if (!currentProfile || !ethers) {
      dispatch(
        exchangeRefillFail({
          exchange: exchangeAddress,
          error: `Something went wrong, current profile is undefined! ${currentProfile}`,
        })
      );
      return;
    }

    try {
      let accountAddress: string | undefined;
      accountAddress = currentProfile.additional_properties?.commonshoodWallet;

      if (accountAddress == null) {
        dispatch(
          exchangeRefillFail({
            exchange: exchangeAddress,
            error: `Something went wrong, wallet address of current profile is undefined! ${currentProfile}`,
          })
        );
        return;
      }

      const coinsOfferedAddr = [];
      const trueAmountsOffered = [];

      for (let coin of coinsOffered) {
        coinsOfferedAddr.push(coin.address);
        trueAmountsOffered.push(
          parseInt(
            assetDecimalRepresentationToInteger(coin.amount, coin.decimals)
          )
        );
      }

      await refillExchange(
        ethers,
        accountAddress,
        exchangeAddress,
        coinsOfferedAddr,
        trueAmountsOffered,
        repeatings
      );

      dispatch(exchangeRefillSuccess({ exchange: exchangeAddress }));
    } catch (error: any) {
      logger.debug("Error =>", error);
      const errorMessage = error?.message
        ? error.message
        : "ERROR -> Something went wrong while retriving exchanges";
      dispatch(
        exchangeRenewFail({ exchange: exchangeAddress, error: errorMessage })
      );
    }
  };
};

export const exchangeChangeExpirationAndRefill = (
  exchangeAddress: string,
  coinsOffered: any[],
  repeatings: number,
  expiration: number
) => {
  logger.info("[IN EXCHANGE MODIFY action]");

  return async (dispatch: Dispatch, getState: () => RootState) => {
    dispatch(exchangeModifyStart({ exchange: exchangeAddress }));

    const ethers = getState().ethers.ethersInstance;
    const currentProfile = getState().user.currentProfile;
    if (!currentProfile || !ethers) {
      dispatch(
        exchangeModifyFail({
          exchange: exchangeAddress,
          error: `Something went wrong, current profile is undefined! ${currentProfile}`,
        })
      );
      return;
    }

    try {
      let accountAddress: string | undefined;
      accountAddress = currentProfile.additional_properties?.commonshoodWallet;

      if (accountAddress == null) {
        dispatch(
          exchangeModifyFail({
            exchange: exchangeAddress,
            error: `Something went wrong, wallet address of current profile is undefined! ${currentProfile}`,
          })
        );
        return;
      }

      const coinsOfferedAddr = [];
      const trueAmountsOffered = [];

      for (let coin of coinsOffered) {
        coinsOfferedAddr.push(coin.address);
        trueAmountsOffered.push(
          parseInt(
            assetDecimalRepresentationToInteger(coin.amount, coin.decimals)
          )
        );
      }

      await refillExchange(
        ethers,
        accountAddress,
        exchangeAddress,
        coinsOfferedAddr,
        trueAmountsOffered,
        repeatings
      );
      await renewExchange(ethers, accountAddress, exchangeAddress, expiration);

      dispatch(exchangeModifySuccess({ exchange: exchangeAddress }));
    } catch (error: any) {
      logger.debug("Error =>", error);
      const errorMessage = error?.message
        ? error.message
        : "ERROR -> Something went wrong while retriving exchanges";
      dispatch(
        exchangeModifyFail({ exchange: exchangeAddress, error: errorMessage })
      );
    }
  };
};

export const getExchangeList = (
  status: number,
  onlyOwned: boolean,
  index: number,
  amount: number
) => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    dispatch(exchangePaginationStart());
    const currentProfile = getState().user.currentProfile;
    if (currentProfile == null) {
      exchangePaginationFail({
        error: `Something went wrong, current profile is undefined! ${currentProfile}`,
      });
      return;
    }
    const accountAddress =
      currentProfile.additional_properties?.commonshoodWallet;
    if (accountAddress == null) {
      exchangePaginationFail({
        error: `Something went wrong, wallet address of current profile is undefined! ${currentProfile}`,
      });
      return;
    }
    try {
      const ethers = getState().ethers.ethersInstance;
      let exchangeToAddList: any[] = await getExchanges(
        accountAddress,
        status,
        onlyOwned,
        index,
        amount
      );
      for (const exchange of exchangeToAddList) {
        exchange.owner = await getOwnerInfo(ethers, exchange, accountAddress);
        exchange.coinsOfferedData = exchange.coinsOfferedData.map(
          (coin: any) => {
            let amount = parseFloat(
              assetIntegerToDecimalRepresentation(
                coin.amount + "",
                coin.decimals
              )
            );
            if (coin.decimals === 0) {
              amount = parseInt(amount.toString());
            }
            const logo = getIpfsUrl(coin.logoUrl);
            return { ...coin, logoUrl: logo, amount: amount };
          }
        );
        exchange.coinsRequiredData = exchange.coinsRequiredData.map(
          (coin: any) => {
            let amount = parseFloat(
              assetIntegerToDecimalRepresentation(
                coin.amount + "",
                coin.decimals
              )
            );
            if (coin.decimals === 0) {
              amount = parseInt(amount.toString());
            }
            const logo = getIpfsUrl(coin.logoUrl);
            return { ...coin, logoUrl: logo, amount: amount };
          }
        );
      }
      console.log("exchangeToAddList", exchangeToAddList);
      dispatch(
        exchangePaginationAdd({ exchanges: exchangeToAddList as Exchange[] })
      );
      dispatch(exchangePaginationSuccess());
    } catch (error: any) {
      logger.debug("[coin - coinGetList] something went wrong:", error);
      dispatch(exchangePaginationFail({ error }));
      return;
    }
  };
};

const getOwnerInfo = async (
  ethers: any,
  exchange: any,
  accountAddress: any
) => {
  let ownerData;

  const res: UserNameType | undefined = await getUserNameByAddress(
    exchange.owner
  );
  if (res) {
    const ownerAvatar = calculateAvatar(res.email, res.first_name);
    ownerData = {
      address: exchange.owner,
      firstName: res.first_name,
      lastName: res.last_name,
      email: res.email,
      avatar: ownerAvatar,
      realm: "user",
    };
  }
  return ownerData;
};

export default exchangeSlice.reducer;
