import { useMediaQuery } from "@material-ui/core";
import { createContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useAppSelector } from "../store/hooks";

export const TutorialContext = createContext<{
    closeTutorial: () => void;
    openTutorialDesktop: () => void;
    openTutorialMobile: () => void;
}>({
    closeTutorial: () => { },
    openTutorialDesktop: () => { },
    openTutorialMobile: () => { }
});
export const TutorialProvider = (props: any) => {
    const children = props.children;
    const history = useHistory();
    const isMobile = useMediaQuery('(max-width: 600px)');
    const profile = useAppSelector(state => state.user.user);


    useEffect(() => {
        if(profile == null) return;
        if (localStorage.getItem('tutorial') !== 'true') {
            if (isMobile)
                openTutorialMobile();
            else
                openTutorialDesktop();
        }
    }, [profile])

    const closeTutorial = () => {
        history.push('/');
        localStorage.setItem('tutorial', 'true');
    }

    //TODO tutorial link

    const openTutorialDesktop = () => {
        history.push('/tutorial/desktop');
    }

    const openTutorialMobile = () => {
        history.push('/tutorial/mobile');
    }

    const values = {
        closeTutorial,
        openTutorialDesktop,
        openTutorialMobile
    }

    return <TutorialContext.Provider value={values}> {children} </TutorialContext.Provider>;
};
