import { useContext, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { getTokensFM } from "../../store/slices/coinSlice";
import { checkIfUserWasRewarded, getCategories, getItemsOfLoggedUserSlice } from "../../store/slices/libOfThingsSlice";
import { collectionGetList } from "../../store/slices/nftSlice";
import { MyAccountContainer } from "./MyAccount/MyAccountContainer";
import { NewArrivalsContainer } from "./NewArrivals/NewArrivalsContainer";
import { Box, Grid, useMediaQuery } from "@material-ui/core";
import { CosoRewardDialog } from "./ReardDialog/CosoRewardDialog";
import { ItemsInHome } from "./ItemsInHome/ItemsInHome";
import { CustomToaster } from "../Notifications/CustomToaster";
import { TutorialFab } from "./Tutorial/TutorialFab";
import { TutorialContext } from "../../providers/TutorialProvider";

export const LibOfThingsHome = () => {
    const { openTutorialDesktop, openTutorialMobile } = useContext(TutorialContext);
    const dispatch = useAppDispatch();
    const currentProfile = useAppSelector(state => state.user.currentProfile);
    const isMobile = useMediaQuery('(max-width:600px)');

    useEffect(() => {
        if (currentProfile != null) {
            dispatch(getCategories());
            dispatch(checkIfUserWasRewarded());
            dispatch(collectionGetList());
            dispatch(getTokensFM("", false, false, -1));
        }
    }, [currentProfile])

    useEffect(() => {
        (async () => {
            await dispatch(getItemsOfLoggedUserSlice())
        })()
    }, [])

    const tutorialFabCallback = () => {
        if (isMobile)
            openTutorialMobile();
        else
            openTutorialDesktop();
    }

    return (
        <>
            <Box>
                <TutorialFab callback={tutorialFabCallback} />
                <CosoRewardDialog />
                <Grid container direction="column" spacing={2}>
                    <Grid item xs={12} style={{
                        marginLeft: '16px'
                    }}>
                        <MyAccountContainer />
                    </Grid>
                    <Grid item xs={12} style={{
                        margin: '16px'
                    }}>
                        <NewArrivalsContainer />
                    </Grid>
                    <Grid item xs={12} style={{ margin: '16px' }}>
                        <ItemsInHome />
                    </Grid>
                    <Grid item xs={12} style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                    </Grid>
                </Grid>
            </Box>
            <CustomToaster />
        </>

    )
}