import React, { useEffect, useState } from "react";
import { Box, Button, makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
    buttonActive: {
        backgroundColor: 'rgba(218, 93, 62, 1)',
        minWidth: '15px',
        width: '40px',
        borderRadius: '15px',
        marginLeft: '0.2em',
    },
    buttonNotActive: {
        minWidth: '1px',
        width: '5px',
        borderRadius: '100%',
        backgroundColor: 'rgba(235, 176, 76, 1)',
        marginLeft: '0.2em',
        '&:hover': {
            backgroundColor: 'rgba(218, 93, 62, 1)'
        }
    }
});

export const PaginationContainer = ({ pageCount, onChange }: {
    pageCount: number,
    onChange: (selectedPage: number) => Promise<void>
}) => {
    const classes = useStyles();
    const [currentPage, setCurrentPage] = useState(0);

    const handleButtonClick = (index: number) => {
        setCurrentPage(index);
        onChange(index);
    };

    const renderButtons = () => {
        return Array.from({ length: pageCount }, (_, index) => (
            <Button
                key={index}
                onClick={() => handleButtonClick(index)}
                className={`${currentPage === index ? classes.buttonActive : classes.buttonNotActive}`}>
            </Button>
        ));
    };

    const handleArrowClick = (direction: 'left' | 'right') => {
        const newPage = direction === 'left' ? currentPage - 1 : currentPage + 1;
        if (newPage >= 0 && newPage < pageCount) {
            setCurrentPage(newPage);
            onChange(newPage);
        }
    };

    return (
        <>
            {pageCount == 1 && <></>}
            {pageCount > 1 && <>
                <Box style={{ textAlign: 'center' }}>
                    <Button onClick={() => handleArrowClick('left')}>{`<`}</Button>
                    {renderButtons()}
                    <Button onClick={() => handleArrowClick('right')}>{`>`}</Button>
                </Box>
            </>}
        </>

    );
};
