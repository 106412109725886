import { useMediaQuery } from "@material-ui/core";
import { PreorderStatus } from "../../../../types/calendar.type";

export const ActivePreordersStepper = ({ status }: { status: number }) => {
    const useMobileQuery = useMediaQuery('(max-width: 600px)');

    return (
        <>
            {
                (useMobileQuery) ? (
                    <>
                        {
                            status == PreorderStatus.Ended && (
                                <img width={'80%'} src="/svgs/steppers/stepper_completed_mobile.svg"></img>
                            )
                        }
                        {
                            status == PreorderStatus.Accepted && (
                                <img width={'80%'} src="/svgs/steppers/stepper_accepted_mobile.svg"></img>
                            )
                        }
                        {
                            status == PreorderStatus.Pending && (
                                <img width={'80%'} src="/svgs/steppers/stepper_pending_mobile.svg"></img>
                            )
                        }
                        {
                            status == PreorderStatus.Started && (
                                <img width={'80%'} src="/svgs/steppers/stepper_started_mobile.svg"></img>
                            )
                        }
                        {
                            status == PreorderStatus.Denied && (
                                <img width={'80%'} src="/svgs/steppers/stepper_denied_mobile.svg"></img>
                            )
                        }
                    </>
                ) : (
                    <>
                        {
                            status == PreorderStatus.Ended && (
                                <img width={'100%'} src="/svgs/steppers/stepper_completed.svg"></img>
                            )
                        }
                        {
                            status == PreorderStatus.Accepted && (
                                <img width={'100%'} src="/svgs/steppers/stepper_accepted.svg"></img>
                            )
                        }
                        {
                            status == PreorderStatus.Pending && (
                                <img width={'100%'} src="/svgs/steppers/stepper_pending.svg"></img>
                            )
                        }
                        {
                            status == PreorderStatus.Started && (
                                <img width={'100%'} src="/svgs/steppers/stepper_started.svg"></img>
                            )
                        }
                        {
                            status == PreorderStatus.Denied && (
                                <img width={'100%'} src="/svgs/steppers/stepper_denied.svg"></img>
                            )
                        }
                    </>
                )
            }
        </>
    )
}