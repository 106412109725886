import { Button, Grid, Typography } from "@material-ui/core";
import toast from "react-hot-toast";
import { Preorder, PreorderStatusStrings, PreorderStatuses } from "../../../../types/calendar.type";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { allowNft, isApproved } from "../../../../store/slices/nftSlice";
import config from "../../../../config";
import { Item } from "../../../../types/libofthings.type";
import { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { BlackButton } from "../../../../components/Buttons/BlackButton";

const useStyles = makeStyles({
    // buttonContainer: {
    //     marginTop: '3em',
    //     ['@media (max-width:600px)']: {
    //         marginTop: '1em'
    //     }
    // }
})

export const ButtonsToHandleActivePreorders = ({ preorder, removeFromList, item, nftTemplate }: {
    preorder: Preorder,
    removeFromList?: (id: string, accepted: boolean) => void,
    item: Item,
    nftTemplate: string
}) => {
    const styles = useStyles();
    const dispatch = useAppDispatch();
    const loading = useAppSelector(state => state.nft.loading);
    const profile = useAppSelector(state => state.user.currentProfile)
    const [userDidApprove, setUserDidApprove] = useState<boolean>(false);


    useEffect(() => {
        checkIfAddressIsApproved();
    }, []);

    const handleApproveNftToEndLendingPeriod = async () => {
        try {
            await dispatch(allowNft(nftTemplate, config.smartContracts.CALENDAR_ADDRESS, item.idInCollection))
            setUserDidApprove(true);
            toast.success('Approvato');
        } catch (e: any) {
            console.log(e);
            toast.error(e.message)
        }
    }

    const checkIfAddressIsApproved = async () => {
        (async () => {
            const currentAddressApprovedEndOfPreorder = await dispatch(isApproved(nftTemplate, config.smartContracts.CALENDAR_ADDRESS, item.idInCollection))
            if (currentAddressApprovedEndOfPreorder != null) {
                setUserDidApprove(currentAddressApprovedEndOfPreorder);
            }
        })();
    }

    return (
        <Grid container direction="row">
            <Grid item xs={12} style={{ textAlign: 'center', marginBottom: '16px' }}>
                {
                    (preorder.status === PreorderStatuses.Started) && (
                        <>
                            {
                                (preorder.status === PreorderStatuses.Started && !userDidApprove) && <BlackButton text={'Termina'} onClick={handleApproveNftToEndLendingPeriod} disabled={userDidApprove || loading} />
                            }
                            {
                                userDidApprove &&
                                <Typography variant="body2">In attesa della risposta del proprietario/a</Typography>
                            }
                        </>
                    )
                }
                {
                    (preorder.status === PreorderStatuses.Accepted && preorder.borrower !== profile?.additional_properties?.commonshoodWallet) && (
                        <>
                            {
                                preorder.status === PreorderStatuses.Started &&
                                <>
                                    {userDidApprove && <Typography variant="body2" style={{ marginBottom: '16px' }}>&Egrave; stato richiesto il termine del prestito. In attesa della tua risposta.</Typography>}
                                    <BlackButton text={'Accetta termine'} onClick={handleApproveNftToEndLendingPeriod} disabled={userDidApprove || loading} />
                                </>

                            }
                        </>
                    )
                }
            </Grid>
        </Grid>
    );
}