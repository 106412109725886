import { FormikProps } from "formik";
import { ItemInfoInForm } from "../../../types/items.type";
import { Grid, Typography, useMediaQuery, makeStyles } from "@material-ui/core";
import { useEffect, useState } from "react";
import { UploadSummaryItemImage } from "./UploadSummaryItemImage";
import { UploadSummaryButtons } from "./UploadSummaryButtons";
import config from "../../../config";

const style = makeStyles({
    subtitle: {
        color: 'rgba(129, 129, 129, 1)',
        fontSize: '12px',
        lineHeight: '15.18px',
    },
    confirmCircle: {
        width: '158px',
        height: '158px',
        ['@media (max-width: 600px)']: {
            width: '72px',
            height: '72px',
        }
    },
});


export const UploadSummaryItemUpload = ({ buttonsShown, formik }: {
    buttonsShown: boolean,
    formik: FormikProps<ItemInfoInForm>
}) => {
    const classes = style();
    const isMobile = useMediaQuery('(max-width:600px)');
    const dateOptions: Intl.DateTimeFormatOptions = { day: '2-digit', month: '2-digit', year: 'numeric' };

    return (
        <>
            <Grid container direction="row" spacing={3}>
                <Grid item xs={6} style={{ marginTop: '1em' }}>
                    <UploadSummaryItemImage formik={formik} />
                </Grid>
                <Grid item xs={6} style={{ marginTop: '1em' }}>
                    {
                        !isMobile && (
                            <Grid container direction="row" spacing={3}>
                                <Grid item xs={12}>
                                    <Typography variant="h5">{formik.values.name}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle1" className={classes.subtitle}>Pubblicato il {new Date().toLocaleString(navigator.language, dateOptions)}</Typography>
                                </Grid>
                            </Grid>
                        )
                    }
                    <Grid container direction="row" style={{ marginTop: '0.2em' }} spacing={2}>
                        <Grid item xs={12} sm={4} md={4}>
                            <Typography variant="body1">Categoria: <b>{formik.values.category}</b></Typography>
                        </Grid>
                        <Grid item xs={12} sm={4} md={4}>
                            <Typography variant="body1">Prezzo giornaliero: <b>{formik.values.price}</b></Typography>
                        </Grid>  
                    </Grid>
                </Grid>
                <Grid item xs={12} justifyContent="center">
                    <Grid item xs={12} style={{ textAlign: 'center' }}>
                        <img src="/svgs/confirmation_circle.svg" className={classes.confirmCircle}></img>
                    </Grid>
                    <Grid item xs={12} style={{ textAlign: 'center', marginTop: '16px' }}>
                        <Typography variant="h5">Ottimo!</Typography>
                    </Grid>
                    <Grid container style={{ marginTop: '1em', textAlign: 'center' }} >
                        <Grid item xs={12} style={{ textAlign: 'center' }}>
                            <Typography variant="subtitle2">Il tuo oggetto è stato caricato correttamente<br />nella biblioteca delle cose.<br />Hai ricevuto 5 {config.project.tokenSymbol} nel tuo portafoglio.</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid> 
        </>

    );
};