import {CenterFocusWeak, LocalOffer, Toll} from "@material-ui/icons";

export type AssetType={
    token: {
        name: 'token',
        id: 0,
        decimals: 2,
        icon: JSX.Element //TODO fix more specific type
    },
    goods: {
        name: 'goods',
        id: 1,
        decimals: 0,
        icon: JSX.Element //TODO fix more specific type
    },
    nft: {
        name: 'nft',
        id: 2,
        decimals: 0,
        icon: JSX.Element //TODO fix more specific type
    }
}
export const assetsType: AssetType= Object.freeze({
    token: {
        name: 'token',
        id: 0,
        decimals: 2,
        icon: <Toll/>
    },
    goods: {
        name: 'goods',
        id: 1,
        decimals: 0,
        icon: <LocalOffer/>
    },
    nft: {
        name: 'nft',
        id: 2,
        decimals: 0,
        icon: <CenterFocusWeak/> //TODO fix more specific type
    }
});
