import axiosWalletApi from '../utilities/backend/axios-walletapi'
import {logger} from "../utilities/logger/logger";
import {AxiosResponse} from "axios";


export type Wallet = {
    found: boolean;
    address: string;
    privateKey: string;
};

/**
 * This method ask the WalletAPI service to return the wallet of the currently logged user
 * @param autogenerate at true tells the backend to create the wallet if the user still hasn't one,
 *                      if the user has already one, it is ignored by the service
 */
export const getWallet = async (autogenerate: boolean = true) : Promise<Wallet> => {
    let endpoint = 'wallets/private-key';
    if (autogenerate) {
        endpoint += '?autogenerate=true';
    }

    try{
        const response : AxiosResponse<{address: string, private_key: string}> = await axiosWalletApi.get(endpoint);
        logger.log('Wallet api wallet response: ', response);
        const wallet = {
            found: true,
            address: response.data.address,
            privateKey: response.data.private_key,
        }
        return wallet;

    }catch(e){
        logger.log('Error while trying to get the wallet from our WALLETAPIs');
        return {
            found: false,
            address: '',
            privateKey: '',
        }
    }
};
