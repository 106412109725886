import { Grid, useMediaQuery } from "@material-ui/core"
import { AccountAction, AccountActionWithDashedBorder } from "./AccountActions"
import { useHistory } from "react-router-dom";

export const ListOfActions = () => {
    const history = useHistory();
    const isMobile = useMediaQuery('(max-width:600px)');

    return (
        <Grid container spacing={2} style={{ width: '100%', textAlign: 'center' }}>
            <Grid item xs={6} sm={3}>
                <AccountAction
                    link={'/user/items'}
                    title={'Metto in prestito'}
                />
            </Grid>
            <Grid item xs={6} sm={3}>
                <AccountAction
                    link={'/user/requests'}
                    title={'Chiedo in prestito'}
                />

            </Grid>
            {
                !isMobile && (
                    <img src="/svgs/line.svg" alt="line" />
                )
            }
            <Grid item xs={12} sm={3} onClick={() => { history.push('/items/upload') }}>
                <AccountActionWithDashedBorder
                    link={'/items/'}
                    title={'Aggiungi un nuovo oggetto'}
                />
            </Grid>
        </Grid>
    )
}