import { Box, Grid, Typography, makeStyles, useMediaQuery } from "@material-ui/core";
import { Preorder, PreorderStatus } from "../../../../types/calendar.type";
import { ItemImage } from "../../MyInventory/MyItems/ItemImage";
import { useContext, useEffect, useState } from "react";
import { Item } from "../../../../types/libofthings.type";
import { getItemInfoByID } from "../../../../api/libOfThingsAPI";
import { getCollectionBySymbol } from "../../../../api/nftAPI";
import { ButtonsToHandleActivePreorders } from "../Active/ButtonsToHandleActivePreorders";
import { useTranslation } from "react-i18next";
import { FirstLifeContextImpl } from "../../../../providers/FirstLifeProvider";
import PreorderStepper from "../Steppers/PreorderStepper";

const useStyles = makeStyles({
    preorder: {
        background: 'white',
        width: '100%',
        borderRadius: '18px',
    },
    centerOnlyInMobile: {
        ['@media (max-width:600px)']: {
            textAlign: 'center',
        }
    },
    requestDateContainer: {
        ['@media (max-width:600px)']: {
            textAlign: 'center',
        },
        ['@media (min-width:600px)']: {
            textAlign: 'right',
        }
    },
    hideInMobile: {
        ['@media (max-width:600px)']: {
            display: 'none',
        }
    },
    displayOnlyInMobile: {
        ['@media (min-width:600px)']: {
            display: 'none',
        }
    }
})

export const MyRequestsPreorderInList = ({ preorder, textUnderStepper }: {
    preorder: Preorder,
    textUnderStepper: string
}) => {
    const [item, setItem] = useState<Item>();
    const [startDate, setStartDate] = useState<string>('');
    const [endDate, setEndDate] = useState<string>('');
    const [requestDate, setRequestDate] = useState<string>('');
    const [collectionAddress, setCollectionAddress] = useState<string>('');
    const [status, setStatus] = useState<number>(0);
    const styles = useStyles();
    const useMobileQuery = useMediaQuery('(max-width: 600px)');
    const { t } = useTranslation('LibOfThings');
    const [lenderEmail, setLenderEmail] = useState<string>('');
    const { getUserByAddress } = useContext(FirstLifeContextImpl);


    useEffect(() => {
        (async () => {
            const item = await getItemInfoByID(preorder.itemID);
            if (item != null) {
                const collection = await getCollectionBySymbol(item.collectionSymbol);
                setCollectionAddress(collection.contractAddress);
            }
            setItem(item);
            await retrieveLenderEmail();
        })()
    }, [])

    const retrieveLenderEmail = async () => {
        if (preorder.lender != null) {
            const user = await getUserByAddress(preorder.lender);
            if (user != null) {
                setLenderEmail(user.email);
            }
        }
    }

    useEffect(() => {
        if (preorder != null) {
            const currentLocale = navigator.language;
            const dateOptions: Intl.DateTimeFormatOptions = { day: '2-digit', month: '2-digit', year: 'numeric' };
            const startDate = new Date(preorder.startDate * 1000);
            const endDate = new Date(preorder.endDate * 1000);
            const requestDate = new Date(preorder.timestamp * 1000);

            setStartDate(startDate.toLocaleString(currentLocale, dateOptions));
            setEndDate(endDate.toLocaleString(currentLocale, dateOptions));
            setRequestDate(requestDate.toLocaleString(currentLocale, dateOptions));
            handleStatus(preorder.status);
        }
    }, [preorder])

    const handleStatus = (status: string) => {
        switch (status) {
            case 'Pending':
                setStatus(PreorderStatus.Pending);
                break;
            case 'Accepted':
                setStatus(PreorderStatus.Accepted);
                break;
            case 'Started':
                setStatus(PreorderStatus.Started);
                break;
            case 'Ended':
                setStatus(PreorderStatus.Ended);
                break;
            case 'Cancelled':
                setStatus(PreorderStatus.Cancelled);
                break;
            case 'Denied':
                setStatus(PreorderStatus.Denied);
                break;
            default:
                break;
        }
    }

    return (
        <Grid container className={styles.preorder} spacing={1} >
            {
                (preorder != null && item != null && preorder.startDate != null) && (
                    <>
                        <Grid item xs={4} sm={2} style={{ paddingLeft: '20px' }} >
                            <ItemImage
                                collectionSymbol={item.collectionSymbol}
                                id={item.idInCollection}
                                option={undefined}
                                respectRatio={true}
                                style={{ display: 'block', margin: 'auto' }}
                            />
                            <Grid container>
                                <Grid item xs={12} style={{ textAlign: 'center' }}>
                                    <Typography variant="body1" style={{ fontWeight: 'bold' }}>{item.name}</Typography>
                                </Grid>
                                <Grid item xs={12} style={{ textAlign: 'center' }}>
                                    <Typography variant="body1" style={{ fontWeight: 'bold', color: 'rgba(218, 93, 62, 1)' }}>{item.category[0].concat(item.category.slice(1).toLowerCase())}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={1} className={styles.hideInMobile} style={{
                        }}>
                            <img height={'90%'} src="/svgs/orange_line_desktop.svg" className={styles.hideInMobile} style={{ display: 'block', margin: 'auto' }} />
                        </Grid>
                        <Grid item xs={8} sm={9}>
                            <Grid container spacing={1} style={{ marginTop: '1em' }}>
                                <Grid item xs={12} sm={6} className={styles.centerOnlyInMobile}>
                                    <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                                        {t('requestNoun')} {preorder.preorderID.slice(0, 3).concat(preorder.preorderID.slice(-4)).toLocaleUpperCase()}
                                    </Typography>
                                </Grid>
                                {
                                    !useMobileQuery && (
                                        <Grid item xs={12} sm={6} style={{ padding: '16px 30px 16px 30px', textAlign: 'right' }} >
                                            <Typography variant="body2"  >{lenderEmail}</Typography>
                                        </Grid>
                                    )
                                }
                                <Grid item xs={12} className={styles.centerOnlyInMobile}>
                                    {
                                        (useMobileQuery) ? (
                                            <Typography variant="body1" >Richiesto il <strong>{requestDate}</strong><br />per il periodo dal<br /> <strong>{startDate}</strong> al <strong>{endDate}</strong></Typography>
                                        ) : (
                                            <Typography variant="body1" >Richiesto il <strong>{requestDate}</strong> per il periodo dal <strong>{startDate}</strong> al <strong>{endDate}</strong></Typography>
                                        )
                                    }
                                </Grid>
                                <Grid item xs={12} md={7} className={`${styles.centerOnlyInMobile} ${styles.hideInMobile}`} style={{
                                    marginTop: '1em'
                                }}>
                                    <PreorderStepper preorder={preorder} lender={true} />
                                </Grid>
                                {
                                    (status !== PreorderStatus.Pending && !useMobileQuery) &&
                                    <Grid item md={5} className={styles.hideInMobile} style={{ width: '100%' }}>
                                        <Grid container justifyContent="flex-end" style={{ paddingRight: '1em', paddingTop: '1em' }}>
                                            <Grid item style={{ width: '16.5em' }}>
                                                {status === PreorderStatus.Started &&
                                                    <Typography align="center">Contatta il/la proprietario/a<br />per riconsegnare l’oggetto<br />e clicca su termina per<br /> concludere il prestito.</Typography>
                                                }
                                                <ButtonsToHandleActivePreorders preorder={preorder} item={item} nftTemplate={collectionAddress} />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                }
                                {
                                    (status === PreorderStatus.Pending && !useMobileQuery) && (
                                        <Grid item md={5} className={styles.hideInMobile} style={{ width: '100%' }}>
                                            <Grid container direction="row" justifyContent="flex-end" alignContent="flex-end" style={{ width: '100%', height: '100%' }}>
                                                <Typography style={{ marginRight: '1em' }} variant="subtitle2">A breve il/la proprietario/a ti ricontatterà!</Typography>
                                            </Grid>
                                        </Grid>
                                    )
                                }
                            </Grid>
                        </Grid>
                        <Grid item xs={12} className={styles.displayOnlyInMobile} style={{
                            textAlign: 'center',
                        }}>
                            <PreorderStepper preorder={preorder} lender={true} />
                        </Grid>

                        <Grid item style={{ textAlign: 'center', marginLeft: 'auto', marginRight: 'auto', width: '16.5em' }} className={styles.displayOnlyInMobile}>
                            <Typography style={{ paddingTop: '1em' }}>{textUnderStepper}</Typography>
                            {status === PreorderStatus.Started &&
                                <Typography align="center" style={{ marginTop: '1rem', marginBottom: '1rem' }}>Hai riconsegnato l’oggetto? Clicca sul tasto termina per concludere il prestito</Typography>
                            }
                            <ButtonsToHandleActivePreorders preorder={preorder} item={item} nftTemplate={collectionAddress} />
                        </Grid>
                        {
                            useMobileQuery && (
                                <Grid item xs={12} style={{ textAlign: 'center', paddingBottom: '1em' }}>
                                    <Typography variant="body1" style={{ fontWeight: 'bold' }}>{lenderEmail}</Typography>
                                </Grid>
                            )
                        }
                        {
                            (status === PreorderStatus.Pending && useMobileQuery) && (
                                <>
                                    <Grid item xs={12} style={{ textAlign: 'center', marginBottom: '0.5em' }}>
                                        <Typography style={{
                                        }} variant="subtitle2">Inviata</Typography>
                                    </Grid>
                                    <Grid item xs={12} style={{ textAlign: 'center', marginBottom: '1em' }}>
                                        <Typography style={{
                                        }} variant="subtitle2">A breve il/la proprietario/a ti ricontatterà!</Typography>
                                    </Grid>
                                </>
                            )
                        }

                    </>
                )
            }
        </Grid >
    )
}