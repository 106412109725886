import { Grid, Typography, makeStyles } from "@material-ui/core";

import Tab8Img1 from "../../../../assets/img/tutorial/tab5img1.svg"
import Tab8Img2 from "../../../../assets/img/tutorial/tab5img2.svg"


const styles = makeStyles({
    tutorialPageTitle: {
        fontFamily: 'Be Vietnam Pro',
        fontSize: '30px',
        fontWeight: 600,
        lineHeight: '38px',
        letterSpacing: '0em',
        textAlign: 'center',
    },
    tutorialText: {
        fontFamily: 'Be Vietnam Pro',
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '20px',
        letterSpacing: '0em',
        textAlign: 'center',
    },
    tutorialPageSubtitle: {
        fontFamily: 'Be Vietnam Pro',
        fontSize: '24px',
        fontWeight: 600,
        lineHeight: '30px',
        letterSpacing: '0em',
        textAlign: 'center',
    }
});


export const MobileTab8 = () => {
    const style = styles();

    return (
        <>
            <Typography className={style.tutorialPageTitle} style={{ height: 0 }}>Chiedere in prestito</Typography>
            <Grid container direction="column" justifyContent="center" alignContent="center">
                <Grid item><img src={Tab8Img1} /></Grid>
                <Grid item><img src={Tab8Img2} /></Grid>
            </Grid>
            <Typography className={style.tutorialPageSubtitle} style={{ height: 0 }}>
                Fine prestito
            </Typography>
            <Typography className={style.tutorialText}>
                Conferma l’inizio del prestito cliccando il tasto “oggetto ricevuto” nella sezione “Chiedo in prestito”. Per terminare il prestito, una volta che hai riconsegnato l’oggetto, vai alla sezione “Chiedo in prestito” poi alla sezione “attivi“ e clicca il tasto “termina”
            </Typography>
        </>
    );
}