import { Badge, Icon } from "@material-ui/core";
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import NotificationMenuList from "./NotificationMenuList";
import { useEffect } from "react";
import io, { Socket } from "socket.io-client";
import config from "../../config";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
    notificationGetAllMineUnread,
    notificationSocketAuthentication,
    notificationSocketAuthenticationReset,
} from "../../store/slices/notificationSlice";
import { useTranslation } from "react-i18next";
import { getAllMyNotification, getUnreadNotification } from "../../api/notificationAPI";

type NotificationBellPropsType = {
    handleMenu: (event: any) => void,
    anchorEl: EventTarget & Element | null,
    onClose: () => void
}

const NotificationBell = (NotificationBellProps: NotificationBellPropsType) => {
    const dispatch = useAppDispatch()
    let socket: Socket;
    let notifyIcon = (<Icon>notifications</Icon>);
    const open = Boolean(NotificationBellProps.anchorEl);

    const notificationSocketAuthenticating = useAppSelector(state => state.notification.notificationSocketAuthenticating)
    const notificationSocketAuthenticated = useAppSelector(state => state.notification.notificationSocketAuthenticated)
    const authToken = useAppSelector(state => state.auth.idToken)
    const notfOfCurrentSession = useAppSelector(state => state.notification.notificationsOfCurrentSession)
    const currentProfile = useAppSelector(state => state.user.currentProfile)
    const unreadNotfLoaded = useAppSelector(state => state.notification.unreadNotificationsLoaded)

    const notificationList = [...notfOfCurrentSession]
        .sort((notf1, notf2) => { //order with last created in 0 position
            const notf1_message = JSON.parse(notf1.message);
            const notf2_message = JSON.parse(notf2.message);
            return notf2_message.timestamp - notf1_message.timestamp;
        })

    if (notfOfCurrentSession.length > 0) {
        notifyIcon = (
            <Badge color='secondary' overlap="rectangular" badgeContent={notfOfCurrentSession.length}>
                <Icon>notifications</Icon>
            </Badge>
        )
    }

    useEffect(() => {
        if (authToken && !socket) {
            socket = io(config.network.notifications.url);
            if (socket && !notificationSocketAuthenticating) {
                if (!notificationSocketAuthenticated) { //Socket not authenticated
                    dispatch(notificationSocketAuthentication(socket))
                }
            }
        }
    }, []);

    useEffect(() => {
        if (notificationSocketAuthenticated) {
            socket = io(config.network.notifications.url);
            if (authToken && socket && notificationSocketAuthenticated) {
                if (!unreadNotfLoaded) {
                    dispatch(notificationGetAllMineUnread())
                }
            }
        }
    }, [notificationSocketAuthenticated]);

    useEffect(() => {

        dispatch(notificationSocketAuthenticationReset())
        if (authToken && !socket) {
            socket = io(config.network.notifications.url);
            if (socket && !notificationSocketAuthenticating) {
                dispatch(notificationSocketAuthentication(socket))
                dispatch(notificationGetAllMineUnread())
            }
        }
    }, [currentProfile]);


    return (
        <>
            <Badge 
                invisible={notificationList.length === 0}
                color="secondary"
                overlap="circle"
                variant="dot"
            >
                <NotificationsNoneIcon
                    onClick={NotificationBellProps.handleMenu}
                    fontSize="large"
                    style={{
                        color: "black"
                    }}
                >
                    {notifyIcon}
                </NotificationsNoneIcon>
            </Badge>
    
            <NotificationMenuList
                notificationList={notificationList}
                id="menu-appbar"
                anchorEl={NotificationBellProps.anchorEl}
                open={open}
                onClose={() => NotificationBellProps.onClose()}
            />
        </>
    );
}

export default (NotificationBell);