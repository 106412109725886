import { configureStore } from "@reduxjs/toolkit";

import authReducer from "./slices/authSlice";
import coinReducer from "./slices/coinSlice";
import fileReducer from "./slices/fileSlice";
import notificationReducer from "./slices/notificationSlice";
import userReducer from "./slices/userSlice";
import ethersReducer from "./slices/ethersSlice";
import nftReducer from "./slices/nftSlice";
import libOfThingsReducer from "./slices/libOfThingsSlice";
import calendarReducer from "./slices/calendarSlice";
import exchangeReducer from "./slices/exchangeSlice";

const store = configureStore({
  reducer: {
    auth: authReducer,
    coin: coinReducer,
    file: fileReducer,
    notification: notificationReducer,
    user: userReducer,
    ethers: ethersReducer,
    nft: nftReducer,
    libOfThings: libOfThingsReducer,
    calendar: calendarReducer,
    exchange: exchangeReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActionPaths: ["payload.ethersInstance", "payload.provider"],
        ignoredPaths: ["ethers"],
      },
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
