import { Card, CardContent, Grid, Typography, makeStyles } from "@material-ui/core"
import { Item } from "../../../../types/libofthings.type"
import { ItemCardMedia } from "./ItemCardMedia";
import { useHistory } from "react-router-dom";
import { getCollectionBySymbol } from "../../../../api/nftAPI";
import { useEffect, useState } from "react";
import { CollectionData } from "../../../../types/nft.types";

const style = makeStyles({
    card: {
        maxWidth: 240,
        border: '2px solid white',
        ['@media (max-width:600px)']: {
            height: 170
        },
        '&:hover': {
            border: '2px solid rgba(218, 93, 62, 1)'
        }
    },

})

export const ItemCard = ({ item }: {
    item: Item,
}) => {
    const classes = style()
    const navigate = useHistory();
    const [collection, setCollection] = useState<CollectionData>();

    useEffect(() => {
        (async () => {
            const collection = await getCollectionBySymbol(item.collectionSymbol);
            setCollection(collection);
        })();
    }, [item]);

    const handleClikGoToItemPage = async () => {
        if (item != null && collection != null && item.itemID != null) {
            const collection = await getCollectionBySymbol(item.collectionSymbol);
            navigate.push(`/items/${collection.contractAddress}/${item.itemID}`);
        }
    }

    const showItemNameFixed = (name: string) => {
        const maxChars = 12;
        if (name.length > maxChars) {
            return name.slice(0, maxChars).concat('...');
        }
        return name;
    }

    return (
        <Card className={classes.card} onClick={handleClikGoToItemPage} style={{
            cursor: 'pointer',
            borderRadius: '18px',
        }} >
            <ItemCardMedia
                itemID={item.idInCollection}
                collection={collection}
                hidden={item.hidden}
            />
            <CardContent>
                <Grid container style={{
                    textAlign: 'center',
                }}>
                    <Grid item xs={12}>
                        <Typography variant="h6" style={{
                            position: 'relative',
                            whiteSpace: 'nowrap',
                            fontSize: '16px'
                        }}>{showItemNameFixed(item.name)}</Typography>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}