import { Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
    root: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '24px',
        lineHeight: '30.36px',
        color: 'black',
    },
    styleForFlexboxWithoutScrollbar: { margin: 0, width: "100%" },
});

export const Title = ({ title }: { title: string }) => {
    const classes = useStyles();

    return (
        <Typography className={classes.root} variant="h1" component="h2">
            {title}
        </Typography>
    )
}