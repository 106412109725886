import { Button, Grid, Typography, makeStyles } from "@material-ui/core";
import { useHistory } from "react-router-dom";

const style = makeStyles({
    homeButton: {
        border: '1px solid rgba(218, 93, 62, 1)',
        borderRadius: '15px',
        textTransform: 'none',
        color: 'rgba(218, 93, 62, 1)',
        '&:hover': {
            background: 'rgba(235, 176, 76, 1)',
            color: 'black',
            border: 'none'
        },
        '&:disabled': {
            background: 'rgba(129, 129, 129, 1)',
            color: 'black'
        },
        '&:focus': {
            background: 'rgba(218, 93, 62, 1)',
            color: 'black'
        },
        '@media (max-width:600px)': {
            width: '100%',
        }
    },
    itemsUploadButton: {
        borderRadius: '15px',
        border: '1px',
        textTransform: 'none',
        background: 'black',
        color: 'white',
        '&:hover': {
            background: 'rgba(235, 176, 76, 1)',
            color: 'black',
            border: 'none'
        },
        '&:disabled': {
            background: 'rgba(129, 129, 129, 1)',
            color: 'black'
        },
        '&:focus': {
            background: 'rgba(218, 93, 62, 1)',
            color: 'black'
        },
        '@media (max-width:600px)': {
            width: '100%',
        }
    },
    ['@media (max-width: 600px)']: {
        itemsUploadButton: {
            fontSize: '11px',
            minHeight: '40px'
        }
    },
    link: {
        color: 'rgba(218, 93, 62, 1)',
        '&:hover': {
            color: 'rgba(235, 176, 76, 1)'
        }
    },
});


export const UploadSummaryButtons = ({ buttonsShown }: { buttonsShown: boolean }) => {
    const history = useHistory();
    const classes = style();

    return (
        <>
            {
                buttonsShown && (
                    <Grid container style={{ marginBottom: '16px', marginTop: '16px' }}>
                        <Grid style={{ padding: '4px', textAlign: 'right' }} item xs={6}>
                            <Button className={classes.homeButton} onClick={() => history.push('/')}>
                                Torna alla Home
                            </Button>
                        </Grid>
                        <Grid item xs={6} style={{ padding: '4px', textAlign: 'left' }}>
                            <Button className={classes.itemsUploadButton} variant="contained" onClick={() => history.push('/items/upload')}>
                                Carica un altro oggetto
                            </Button>
                        </Grid>
                        <Grid style={{ textAlign: 'center', marginTop: '16px' }} item xs={12}>
                            <Typography variant="subtitle1">Trovi gli oggetti che hai caricato in <b>
                                <a className={classes.link} style={{ cursor: 'pointer' }} onClick={() => {
                                    history.push('/user/items');
                                }}>"Metto in prestito"</a>
                            </b></Typography>
                        </Grid>
                    </Grid>
                )
            }
        </>
    )
}