import { Box, Grid, makeStyles } from "@material-ui/core"
import { FormikProps } from "formik"
import { ItemInfoInForm } from "../../../types/items.type"

const style = makeStyles({
    box: {
        borderRadius: '18px',
        background: 'white',
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        position: 'relative',
        ['@media (min-width: 600px)']: {
            marginLeft: '1em',
            marginTop: '1em',
            minWidth: '300px',
            minHeight: '300px',
        },
        ['@media (max-width: 600px)']: {
            width: '142px',
            height: '142px',
        }
    },
    input: {
        position: 'absolute',
        top: '0px',
        left: '0px',
        zIndex: 1,
        height: '100%',
        width: '100%',
        cursor: 'pointer',
        margin: 0,
        paddingLeft: '30px',
        background: 'transparent',
        border: 'none',
        fontSize: '0px',
        outline: 0,
        opacity: 0
    },
    image: {
        ['@media (max-width: 600px)']: {
            marginTop: '1em'
        }
    },
    placeholder: {
        ['@media (max-width: 600px)']: {
            width: '50px',
            height: '50px',
        }
    }
})

export const UploadSummaryItemImage = ({ formik }: {
    formik: FormikProps<ItemInfoInForm>
}) => {
    const classes = style();

    return (
        <Box className={classes.box} >
            <Grid container>
                <Grid item xs={12} style={{
                    position: 'relative',
                    padding: 0,
                    textAlign: 'center',
                }}>
                    {
                        formik.values.image ?
                            <img className={classes.image} src={URL.createObjectURL(formik.values.image)} alt="placeholder" width={'80%'} height={'80%'} style={{
                                maxWidth: '300px',
                                maxHeight: '300px',
                            }} /> :
                            (
                                <>
                                    <img src="/svgs/media_image_icon.svg" className={classes.placeholder} alt="placeholder" />
                                </>
                            )
                    }
                </Grid>
            </Grid>

        </Box>
    )

}