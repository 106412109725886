import { Grid, Typography, makeStyles, useMediaQuery } from "@material-ui/core"
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { getTokenWithBalanceFromMetadata } from "../../../api/coinAPI";
import { Token } from "../../../types/coin.type";
import { Skeleton } from "@material-ui/lab";
import config from "../../../config";

const useStyles = makeStyles({
    container: {
        border: '1px solid rgba(0, 0, 0, 1)',
        borderRadius: '15px',
        '@media (min-width: 670px)': {
            width: 'max-content'
        }
    },

    title: {
        padding: '10px'
    }
})

export const ERC20BalanceContainer = ({erc20Info}: {
    erc20Info: Token | undefined
}) => {
    const styles = useStyles();
    const isMobile = useMediaQuery('(max-width: 900px)');


    return (
        <Grid container className={styles.container}>
            <Grid item xs={12}>
                {!isMobile && <Typography className={styles.title} variant="h6">{config.project.tokenSymbol}</Typography>}
            </Grid>
            <Grid item xs={12}>
                {
                    (isMobile) ? <ERC20BalanceContentMobile erc20Info={erc20Info} /> : <ERC20BalanceContentDesktop erc20Info={erc20Info} />
                }
            </Grid>
        </Grid>
    )
}

const ERC20BalanceContentDesktop = ({ erc20Info }: {
    erc20Info: Token | undefined
}) => {
    return (
        <Grid container alignItems="center">
            <Grid item xs={6}>
                <img
                    width={'140px'}
                    height={'140px'}
                    src={config.project.tokenLogo}
                    style={{
                        borderRadius: '50%',
                        margin: '10px'
                    }}
                />
            </Grid>
            <Grid item xs={6} style={{ textAlign: 'center', padding: '10px' }}>
                {
                    (erc20Info != null) ? (
                        <Typography variant="h2">{erc20Info.balance}</Typography>
                    ) : (
                        <Skeleton variant="text" />
                    )
                }

            </Grid>
        </Grid>
    )
}

const ERC20BalanceContentMobile = ({ erc20Info }: {
    erc20Info: Token | undefined
}) => {
    const styles = useStyles();

    return (
        <Grid container alignItems="center">
            <Grid item xs={4}>
                <img
                    width={'70px'}
                    height={'70px'}
                    src={config.project.tokenLogo}
                    style={{
                        borderRadius: '50%',
                        margin: '10px'
                    }}
                />
            </Grid>
            <Grid item xs={4}>
                <Typography className={styles.title} variant="h6">{config.project.tokenSymbol}</Typography>
            </Grid>
            <Grid item xs={4}>
                {
                    (erc20Info != null) ? (
                        <Typography variant="h6">{erc20Info.balance}</Typography>
                    ) : (
                        <Skeleton variant="text" />
                    )
                }
            </Grid>
        </Grid>
    )
}