import { CardMedia, makeStyles } from "@material-ui/core"
import { Item } from "../../../../types/libofthings.type"
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { getCollectionFromSymbol, getURIByIDSlice } from "../../../../store/slices/nftSlice";
import config from "../../../../config";
import { getFromIpfs, getJsonFromResource } from "../../../../api/resourceAPI";
import Skeleton from "@material-ui/lab/Skeleton";
import { CollectionData } from "../../../../types/nft.types";

const style = makeStyles({
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
        borderRadius: '18px, 18px, 0px, 0px'
    }
});

export const ItemCardMedia = ({ itemID, collection, hidden }: {
    itemID: number,
    collection: CollectionData | undefined,
    hidden: boolean
}) => {
    const classes = style();
    const [uri, setUri] = useState<string | null>(null);
    const [metadata, setMetadata] = useState<any>(null);

    const dispatch = useAppDispatch();

    useEffect(() => {
        (async () => {
            if (collection != null) {
                const uri = await dispatch(getURIByIDSlice(itemID, collection.contractAddress))
                if (uri != null) {
                    setUri(uri);
                }
            }
        })()
    }, [collection])

    useEffect(() => {
        (async () => {
            if (uri != null) {
                if (uri.includes(config.network.ipfs.default_url)) {
                    const uriFromIpfs = await getFromIpfs(uri);
                    setMetadata(uriFromIpfs);
                } else {
                    setMetadata(await getJsonFromResource(uri));
                }
            }
        })()
    }, [uri])

    useEffect(() => {
        if (metadata !== null) {
            if (metadata.image.includes(config.network.ipfs.default_url)) {
                setMetadata({
                    ...metadata,
                    image: metadata.image.replace(
                        config.network.ipfs.default_url,
                        config.network.ipfs.gateway_url
                    ),
                });
            }
            if (!metadata.image.includes(config.network.ipfs.default_url)) {
                let tempImage = new Image();
                tempImage.src = metadata.image;
            }
        }
    }, [metadata]);


    return (
        <>
            {
                metadata == null ? <Skeleton variant="rect" width={'100%'} height={'125px'} /> :
                    <>
                        <CardMedia
                            className={classes.media}
                            title={`Item image`}
                            image={metadata.image}
                        />
                    </>

            }
        </>
    )
}


