import { createContext, useEffect } from "react";

export const LanguageContext = createContext<{
    
}>({
    
});
export const LanguageProvider = (props: any) => {
    const children = props.children;

    useEffect(() => {
        localStorage.setItem('i18nextLng', 'it');
    }, [])

    return <LanguageContext.Provider value={{}}> {children} </LanguageContext.Provider>;
};
