import { useContext, useEffect, useState } from "react";
import { TutorialContext } from "../../../providers/TutorialProvider";
import { Grid, Typography, makeStyles } from "@material-ui/core";

import GoBackArrow from "../../../assets/img/tutorial/go-back-arrow.svg";
import GoNextArrow from "../../../assets/img/tutorial/go-next-arrow.svg";
import Beckgraund from "../../../assets/img/tutorial/bg-mobile.png"
import BgForStep9 from "../../../assets/img/tutorial/step-for-9.png"
import BgForStep10 from "../../../assets/img/tutorial/step-for-10.png"
import MobileTutorialProgress, { TMobileTutorialProgressStepNumber } from "./MobileProgress";

import { MobileTab1 } from "./MobileTabContent/MobileTab1";
import { MobileTab2 } from "./MobileTabContent/MobileTab2";
import { MobileTab3 } from "./MobileTabContent/MobileTab3";
import { MobileTab4 } from "./MobileTabContent/MobileTab4";
import { MobileTab5 } from "./MobileTabContent/MobileTab5";
import { MobileTab6 } from "./MobileTabContent/MobileTab6";
import { MobileTab7 } from "./MobileTabContent/MobileTab7";
import { MobileTab8 } from "./MobileTabContent/MobileTab8";

export const TutorialMobile = () => {
    const { closeTutorial } = useContext(TutorialContext);
    const [step, setStep] = useState<TMobileTutorialProgressStepNumber>(1);

    const nextStep = () => {
        if (step === 2 || step === 5 || step === 8)
            setStep(9);
        else if (step < 10)
            setStep((step + 1) as TMobileTutorialProgressStepNumber);
        else
            closeTutorial();
    }

    const previousStep = () => {
        if (step === 6 || step === 9)
            setStep(2);
        else if (step > 1)
            setStep((step - 1) as TMobileTutorialProgressStepNumber);
    }

    const closeTutorialWrapper = () => {
        if(step >= 3 && step <= 8)
            setStep(2);
        else
            closeTutorial();
    }

    let currentMobileTab: JSX.Element | null;
    let backgroundImage: string;

    switch (step) {
        case 1:
            currentMobileTab = <MobileTab1 />;
            break;
        case 2:
            currentMobileTab = <MobileTab2 setStep={setStep} />;
            break;
        case 3:
            currentMobileTab = <MobileTab3 />;
            break;
        case 4:
            currentMobileTab = <MobileTab4 />;
            break;
        case 5:
            currentMobileTab = <MobileTab5 />;
            break;
        case 6:
            currentMobileTab = <MobileTab6 />;
            break;
        case 7:
            currentMobileTab = <MobileTab7 />;
            break;
        case 8:
            currentMobileTab = <MobileTab8 />;
            break;
        default:
            currentMobileTab = null; // Handle default case or remove if not needed
    }

    return (
        <>
            <Grid container direction='column' spacing={1} style={{
                position: 'relative',
                zIndex: 1102,
                height: '100svh',
                // width: '100%',
                backgroundColor: 'rgba(24, 27, 29, 0.9)',
                padding: '1.25rem',
                color: '#EEE',
                backgroundImage: `url(${step === 9 ? BgForStep9 : step === 10 ? BgForStep10 : Beckgraund})`,
                backgroundSize: 'contain',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
            }}>
                <Grid item style={{ margin: '0 auto' }}>
                    <img src="/images/x.png" alt="Close tutorial" onClick={closeTutorialWrapper} style={{ cursor: 'pointer' }} />
                </Grid>
                <Grid item container justifyContent="center" style={{ flexGrow: 1 }}>
                    {currentMobileTab}
                </Grid>
                <Grid item container direction='row' justifyContent='center' style={{ marginTop: 'auto' }}>
                    <img src={GoBackArrow}
                        style={{
                            marginRight: '4rem',
                            cursor: 'pointer',
                            visibility: step === 1 ? 'hidden' : 'visible',
                        }}
                        alt="Go back to previous tutorial page"
                        onClick={previousStep}
                    />
                    <Grid item container justifyContent='center' alignContent='center' style={{ width: 100, height: 24 }}>
                        <MobileTutorialProgress step={step} />
                    </Grid>
                    <img src={GoNextArrow} style={{ marginLeft: '4rem', cursor: 'pointer' }} alt="Go next to previous tutorial page" onClick={nextStep} />
                </Grid>
            </Grid>

        </>
    );
}