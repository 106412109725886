import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { logger } from "../../utilities/logger/logger";
import { uploadFileIpfs, uploadJsonIpfs } from "../../api/resourceAPI";

import {
  createNft,
  createNftCollection,
  getAddressFromSymbolAPI,
  getCollection,
  getCollectionOwned,
  getNftsFromCollection,
  isCollection,
  isCreatedByMe,
  sendNFT,
  isOwner,
  getTokenByID,
  increaseAllowance,
  isAllowed,
} from "../../api/nftAPI";
import {
  CollectionCreateDataSlice,
  collectionProps,
  NftCreateDataSlice,
  nftProps,
  NftSendProps,
} from "../../types/nft.types";
import config from "../../config";

import { Event } from "ethers";

type NftInitialState = {
  error: string | null;
  loading: boolean;
  nftCreated: boolean;
  collectionCreated: boolean;
  collectionLoading: boolean;
  justCreatedCollectionAddress: collectionProps | null;
  nftList: nftProps[];
  page: number;
  amount: number;
  paginationLoading: boolean;
  collectionList: collectionProps[];
  collectionSelected: collectionProps | null;
  nftSendLoading: boolean;
  nftSend: boolean;
  ownerCheck: boolean;
  onGoingOperations: any[];
  uriLoading: boolean;
  uri: string;
  collectionAddressLoading: boolean;
  collectionsBySymbol: { symbol: string; address: string }[];
  isApproved: boolean;
};
const initialState: NftInitialState = {
  error: null,
  loading: false,
  nftCreated: false,
  collectionCreated: false,
  collectionLoading: false,
  justCreatedCollectionAddress: null,
  nftList: [],
  page: 1,
  amount: 0,
  paginationLoading: false,
  collectionList: [],
  collectionSelected: null,
  nftSendLoading: false,
  nftSend: false,
  ownerCheck: false,
  onGoingOperations: [],
  uriLoading: false,
  uri: "",
  collectionAddressLoading: false,
  collectionsBySymbol: [],
  isApproved: false,
};

export const nftSlice = createSlice({
  name: "nft",
  initialState,
  reducers: {
    nftCreateReset(state) {
      state.nftCreated = false;
      state.loading = false;
      state.error = null;
    },
    nftCreateStart(state) {
      state.nftCreated = false;
      state.loading = true;
    },
    nftCreateFail(state, action: PayloadAction<{ error: string }>) {
      state.loading = false;
      state.error = action.payload.error;
    },
    nftCreateSuccess(state) {
      state.nftCreated = true;
      state.loading = false;
    },
    nftGetListStart(state) {
      state.paginationLoading = true;
    },
    nftGetListSuccess(
      state,
      action: PayloadAction<{ nftList: nftProps[]; page: number }>
    ) {
      state.paginationLoading = false;
      state.nftList = state.nftList.concat(action.payload.nftList);
      state.page = action.payload.page;
    },
    nftGetListFail(state, action: PayloadAction<{ error: string }>) {
      state.paginationLoading = false;
      state.error = action.payload.error;
    },
    nftGetListReset(state) {
      state.paginationLoading = false;
      state.nftList = [];
      state.page = 1;
    },
    collectionCreateStart(state) {
      state.collectionLoading = true;
      state.collectionCreated = false;
    },
    collectionCreateFail(state, action: PayloadAction<{ error: string }>) {
      state.collectionLoading = false;
      state.collectionCreated = false;
      state.error = action.payload.error;
    },
    collectionCreateSuccess(
      state,
      action: PayloadAction<{ collection: collectionProps }>
    ) {
      state.collectionCreated = true;
      state.collectionLoading = false;
      state.justCreatedCollectionAddress = action.payload.collection;
      state.amount = 0;
    },
    collectionCreateReset(state) {
      state.collectionCreated = false;
    },
    collectionCreateResetAll(state) {
      state.collectionLoading = false;
      state.collectionSelected = null;
      state.amount = 0;
      state.collectionCreated = false;
      state.justCreatedCollectionAddress = null;
      state.error = null;
    },
    collectionGetListStart(state) {
      state.loading = true;
    },
    collectionGetListSuccess(
      state,
      action: PayloadAction<{ collectionList: collectionProps[] }>
    ) {
      state.loading = false;
      state.collectionList = action.payload.collectionList;
    },
    collectionGetListFail(state, action: PayloadAction<{ error: string }>) {
      state.loading = false;
      state.error = action.payload.error;
    },
    collectionGetListReset(state) {
      state.loading = false;
      state.collectionList = [];
      state.collectionSelected = null;
      state.amount = 0;
    },
    collectionSetSelected(
      state,
      action: PayloadAction<{ selected: collectionProps }>
    ) {
      state.amount = action.payload.selected.nftOwned;
      state.collectionSelected = action.payload.selected;
    },
    collectionResetSelected(state) {
      state.collectionSelected = null;
      state.amount = 0;
      state.page = 1;
    },
    collectionSetAmount(state, action: PayloadAction<{ amount: number }>) {
      state.amount = action.payload.amount;
    },
    // send methods   ///////////////////////////
    nftSendReset(state) {
      state.nftSend = false;
      state.nftSendLoading = false;
      state.error = null;
    },
    nftIsSendReset(state) {
      state.nftSend = false;
      state.error = null;
    },
    nftSendStart(state, action: PayloadAction<{ nftAddress: string }>) {
      state.onGoingOperations.push(action.payload.nftAddress);
      state.nftSend = false;
      state.nftSendLoading = true;
    },
    nftSendFail(
      state,
      action: PayloadAction<{ error: string; nftAddress: string }>
    ) {
      state.onGoingOperations = state.onGoingOperations.filter(
        (item) => item != action.payload.nftAddress
      );
      state.nftSendLoading = false;
      state.error = action.payload.error;
    },
    nftSendSuccess(state, action: PayloadAction<{ nftAddress: string }>) {
      state.onGoingOperations = state.onGoingOperations.filter(
        (item) => item != action.payload.nftAddress
      );
      state.nftSend = true;
      state.nftSendLoading = false;
    },
    getNftURIStart(state) {
      state.uriLoading = true;
    },
    getNftURISuccess(state, action: PayloadAction<{ uri: string }>) {
      state.uriLoading = false;
      state.uri = action.payload.uri;
    },
    getNftURIFail(state, action: PayloadAction<{ error: string }>) {
      state.uriLoading = false;
      state.error = action.payload.error;
    },
    getCollectionFromSymbolStarted(state) {
      state.collectionAddressLoading = true;
    },
    getCollectionFromSymbolFailed(
      state,
      action: PayloadAction<{ error: string }>
    ) {
      state.collectionAddressLoading = false;
      state.error = action.payload.error;
    },
    getCollectionFromSymbolSuccess(
      state,
      action: PayloadAction<{ address: string; symbol: string }>
    ) {
      state.collectionsBySymbol.push({
        address: action.payload.address,
        symbol: action.payload.symbol,
      });
      state.collectionAddressLoading = false;
    },
    nftOwnerCheckStart(state) {
      state.ownerCheck = false;
      state.error = null;
    },
    nftOwnerCheckFail(state, action: PayloadAction<{ error: string }>) {
      state.ownerCheck = false;
      state.error = action.payload.error;
    },
    nftOwnerCheckSuccess(state) {
      state.ownerCheck = true;
    },
    nftOwnerCheckReset(state) {
      state.ownerCheck = false;
      state.error = null;
    },
    allowNftStart(state) {
      state.loading = true;
    },
    allowNftFail(state, action: PayloadAction<{ error: string }>) {
      state.error = action.payload.error;
      state.loading = false;
    },
    allowNftSuccess(state) {
      state.loading = false;
    },
    getIsAllowedStarted(state) {
      state.loading = true;
    },
    getIsAllowedFailed(state, action: PayloadAction<{ error: string }>) {
      state.error = action.payload.error;
      state.loading = false;
    },
    getIsAllowedEnded(state, action: PayloadAction<{ isApproved: boolean }>) {
      state.loading = false;
      state.isApproved = action.payload.isApproved;
    },
  },
});

export const {
  nftCreateReset,
  nftCreateStart,
  nftCreateFail,
  nftCreateSuccess,
  nftGetListStart,
  nftGetListFail,
  nftGetListSuccess,
  nftGetListReset,
  collectionCreateStart,
  collectionCreateFail,
  collectionCreateReset,
  collectionCreateSuccess,
  collectionGetListFail,
  collectionGetListReset,
  collectionGetListStart,
  collectionGetListSuccess,
  collectionSetSelected,
  collectionSetAmount,
  collectionResetSelected,
  nftSendReset,
  nftSendStart,
  nftSendFail,
  nftSendSuccess,
  nftIsSendReset,
  collectionCreateResetAll,
  getNftURIFail,
  getNftURIStart,
  getNftURISuccess,
  getCollectionFromSymbolFailed,
  getCollectionFromSymbolStarted,
  getCollectionFromSymbolSuccess,
  nftOwnerCheckStart,
  nftOwnerCheckFail,
  nftOwnerCheckSuccess,
  nftOwnerCheckReset,
  allowNftFail,
  allowNftStart,
  allowNftSuccess,
  getIsAllowedFailed,
  getIsAllowedStarted,
  getIsAllowedEnded,
} = nftSlice.actions;

const removeEmpty = (obj: any) => {
  Object.keys(obj).forEach((key) => {
    obj[key] &&
      typeof obj[key] === "object" &&
      (obj[key] = removeEmpty(obj[key]));

    (obj[key] === "" || obj[key] === null || obj[key] === undefined) &&
      delete obj[key];
  });
  return Object.keys(obj).length > 0 ? obj : undefined;
};

export const nftCreate = (nftData: NftCreateDataSlice) => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    dispatch(nftGetListReset());
    dispatch(nftCreateStart());

    let creationResponse;

    const currentProfile = getState().user.currentProfile;
    const ethers = getState().ethers.ethersInstance;
    if (currentProfile == null || ethers == null) {
      dispatch(
        nftCreateFail({ error: `currentProfile is not defined in CoinCreate` })
      );
      return;
    }
    const accountAddress =
      currentProfile.additional_properties?.commonshoodWallet;
    if (accountAddress == null) {
      dispatch(nftCreateFail({ error: "account address is undefined" }));
      return;
    }
    if (!nftData.getFromCollection) {
      let imgCid: string = config.network.ipfs.default_url;
      imgCid += await uploadFileIpfs(nftData.image);

      logger.info("img", imgCid);
      nftData.image = imgCid;
    }
    const tokenUri =
      config.network.ipfs.default_url +
      (await uploadJsonIpfs(
        removeEmpty({
          name: nftData.name,
          description: nftData.description,
          image: nftData.image,
          attributes: nftData.attributes,
          isTransferable: nftData.isTransferable,
        })
      ));
    logger.info("json", tokenUri);
    try {
      try {
        if (nftData.collectionAddress !== "") {
          const isCollectionC = await isCollection(
            ethers,
            accountAddress,
            nftData.collectionAddress
          );
          if (!isCollectionC) {
            dispatch(nftCreateFail({ error: "collezione non esistente" }));
          }
        }
      } catch (error: any) {
        dispatch(nftCreateFail({ error }));
      }
      try {
        creationResponse = await createNft(ethers, accountAddress, {
          tokenUri: tokenUri,
          collectionAddress: nftData.collectionAddress,
          numbersNft: nftData.numbersNft,
          isTransferable: nftData.isTransferable,
          type: nftData.type,
        });
        dispatch(nftCreateSuccess());
      } catch (error: any) {
        logger.debug("Something went bad while creating coin:", error);
        dispatch(nftCreateFail({ error }));
      }
    } catch (error: any) {
      dispatch(nftCreateFail({ error }));
    }
    return creationResponse;
  };
};

export const collectionCreate = (collectionData: CollectionCreateDataSlice) => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    let collection;
    dispatch(collectionCreateStart());

    const ethers = getState().ethers.ethersInstance;

    const currentProfile = getState().user.currentProfile;
    if (currentProfile == null || ethers == null) {
      dispatch(
        nftCreateFail({ error: `currentProfile is not defined in CoinCreate` })
      );
      return;
    }
    const accountAddress =
      currentProfile.additional_properties?.commonshoodWallet;
    if (accountAddress == null) {
      dispatch(nftCreateFail({ error: "account address is undefined" }));
      return;
    }

    let imgCid: string = config.network.ipfs.default_url;
    imgCid += await uploadFileIpfs(collectionData.image);

    logger.info("img", imgCid);

    collectionData.image = imgCid;

    collectionData.file = "";

    let collectionURI: string = config.network.ipfs.default_url;
    collectionURI += await uploadJsonIpfs(
      removeEmpty({
        name: collectionData.name,
        symbol: collectionData.symbol,
        image: collectionData.image,
        description: collectionData.description,
        contractFile: collectionData.file,
        attributes: collectionData.attributes,
      })
    );
    logger.info("json", collectionURI);
    try {
      console.log(`Flag`);
      const creationResponse = await createNftCollection(
        ethers,
        accountAddress,
        {
          name: collectionData.name,
          symbol: collectionData.symbol,
          collectionURI,
        }
      );

      const collectionAddress = creationResponse.events.filter((ev: Event) => ev.event === "CollectionCreated")[0].args.collectionAddress;
      console.log("Collection address da evento CollectionCreated",collectionAddress);
      collection = await getCollection(
        ethers,
        accountAddress,
        collectionAddress
      );
      dispatch(
        collectionCreateSuccess({
          collection: collection,
        })
      );
      try {
      } catch (error: any) {
        dispatch(collectionCreateFail({ error }));
      }
    } catch (error: any) {
      console.log(error);
      dispatch(collectionCreateFail({ error }));
    }
    return collection;
  };
};

export const nftGetListFromCollection = (
  collectionContract: collectionProps,
  page: number,
  sortBy: string
) => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    dispatch(nftGetListStart());

    const currentProfile = getState().user.currentProfile;
    const ethers = getState().ethers.ethersInstance;

    if (currentProfile == null || ethers == null) {
      dispatch(
        nftGetListFail({ error: `currentProfile is not defined in CoinCreate` })
      );
      return;
    }
    const accountAddress =
      currentProfile.additional_properties?.commonshoodWallet;
    if (accountAddress == null) {
      dispatch(nftCreateFail({ error: "account address is undefined" }));
      return;
    }
    try {
      try {
        const collection: collectionProps = await getCollection(
          ethers,
          accountAddress,
          collectionContract.contractAddress
        );
        dispatch(collectionSetAmount({ amount: collection.nftOwned }));
        if (page - 1 * 10 <= collection.nftOwned) {
          let nftList: nftProps[];
          nftList = await getNftsFromCollection(
            accountAddress,
            collectionContract.contractAddress,
            page,
            sortBy
          );

          const isCBM = await isCreatedByMe(
            ethers,
            accountAddress,
            collectionContract.contractAddress
          );
          nftList = nftList.map((nft: nftProps) => ({
            ...nft,
            isCreatedByMe: isCBM,
          }));
          dispatch(nftGetListSuccess({ nftList: nftList, page: page }));
        }
      } catch (error: any) {
        logger.debug("Something went bad while creating coin:", error);
        dispatch(nftGetListFail({ error }));
      }
    } catch (error: any) {
      dispatch(nftGetListFail({ error }));
    }
  };
};

export const collectionGetList = () => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    dispatch(collectionGetListStart());

    const currentProfile = getState().user.currentProfile;
    if (currentProfile == null) {
      dispatch(
        collectionGetListFail({
          error: `currentProfile is not defined in CoinCreate`,
        })
      );
      return;
    }
    const accountAddress =
      currentProfile.additional_properties?.commonshoodWallet;
    if (accountAddress == null) {
      dispatch(collectionCreateFail({ error: "account address is undefined" }));
      return;
    }
    try {
      try {
        let collectionList: collectionProps[];
        collectionList = await getCollectionOwned(accountAddress);

        dispatch(collectionGetListSuccess({ collectionList: collectionList }));
      } catch (error: any) {
        logger.debug("Something went bad while creating coin:", error);
        dispatch(collectionGetListFail({ error }));
      }
    } catch (error: any) {
      dispatch(collectionGetListFail({ error }));
    }
  };
};

export const nftSend = (props: NftSendProps) => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    dispatch(nftSendReset());
    dispatch(nftSendStart({ nftAddress: props.tokenId }));
    const currentProfile = getState().user.currentProfile;
    const ethers = getState().ethers.ethersInstance;

    if (currentProfile == null || ethers == null) {
      dispatch(
        nftSendFail({
          error: `currentProfile is not defined in CoinCreate`,
          nftAddress: props.nftContract,
        })
      );
      return;
    }
    const accountAddress =
      currentProfile.additional_properties?.commonshoodWallet;
    if (accountAddress == null) {
      dispatch(
        nftSendFail({
          error: "account address is undefined",
          nftAddress: props.nftContract,
        })
      );
      return;
    }

    try {
      try {
        await sendNFT(
          ethers,
          accountAddress,
          props.to,
          props.nftContract,
          props.tokenId
        );
        logger.info("[NFTSEND]=> nft sended to:", props.to);

        dispatch(nftSendSuccess({ nftAddress: props.nftContract }));
      } catch (error: any) {
        logger.debug("Something went bad while sending nft:", error);
        dispatch(nftSendFail({ error, nftAddress: props.nftContract }));
      }
    } catch (error: any) {
      dispatch(nftSendFail({ error, nftAddress: props.nftContract }));
    }
  };
};

export const getURIByIDSlice = (id: number, collectionAddress: string) => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    dispatch(getNftURIStart());
    const currentProfile = getState().user.currentProfile;
    if (currentProfile == null) {
      dispatch(
        getNftURIFail({ error: `currentProfile is not defined in CoinCreate` })
      );
      return;
    }
    const accountAddress =
      currentProfile.additional_properties?.commonshoodWallet;
    if (accountAddress == null) {
      dispatch(getNftURIFail({ error: "account address is undefined" }));
      return;
    }
    try {
      if (collectionAddress != null) {
        const token = await getTokenByID(collectionAddress, id.toString());
        dispatch(getNftURISuccess({ uri: token.token.tokenUri }));
        return token.token.tokenUri;
      }
    } catch (error: any) {
      console.log(error.message);
      dispatch(getNftURIFail({ error }));
    }
  };
};

export const getCollectionFromSymbol = (symbol: string) => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    dispatch(getCollectionFromSymbolStarted());
    const currentProfile = getState().user.currentProfile;
    if (currentProfile == null) {
      dispatch(
        getCollectionFromSymbolFailed({
          error: `currentProfile is not defined in CoinCreate`,
        })
      );
      return;
    }
    const accountAddress =
      currentProfile.additional_properties?.commonshoodWallet;
    if (accountAddress == null) {
      dispatch(
        getCollectionFromSymbolFailed({ error: "account address is undefined" })
      );
      return;
    }
    try {
      const address = await getAddressFromSymbolAPI(symbol);
      dispatch(
        getCollectionFromSymbolSuccess({ address: address, symbol: symbol })
      );
    } catch (error: any) {
      logger.debug(
        "Something went bad while retrieving the collection address:",
        error
      );
      dispatch(getCollectionFromSymbolFailed({ error }));
    }
  };
};
export const checkNftOwner = (contractAddress: string, tokenId: string) => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    dispatch(nftOwnerCheckStart());
    const currentProfile = getState().user.currentProfile;
    const ethers = getState().ethers.ethersInstance;

    if (currentProfile == null || ethers == null) {
      dispatch(
        nftOwnerCheckFail({
          error: `currentProfile is not defined in CoinCreate`,
        })
      );
      return;
    }
    const accountAddress =
      currentProfile.additional_properties?.commonshoodWallet;
    if (accountAddress == null) {
      dispatch(nftOwnerCheckFail({ error: "account address is undefined" }));
      return;
    }

    try {
      try {
        const isowner = await isOwner(
          ethers,
          accountAddress,
          contractAddress,
          tokenId + ""
        );
        if (!isowner) {
          logger.debug("Owner do not match");
          dispatch(nftOwnerCheckFail({ error: "owner do not match" }));
          return;
        }
        dispatch(nftOwnerCheckSuccess());
      } catch (error: any) {
        logger.debug("Something unexpected happened", error);
        dispatch(nftOwnerCheckFail({ error }));
      }
    } catch (error: any) {
      dispatch(nftOwnerCheckFail({ error }));
    }
  };
};
export const allowNft = (
  nftTemplateAddress: string,
  addressToAllow: string,
  tokenId: number
) => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    dispatch(allowNftStart());
    const currentProfile = getState().user.currentProfile;
    const ethers = getState().ethers.ethersInstance;

    if (currentProfile == null || ethers == null) {
      dispatch(
        allowNftFail({
          error: `currentProfile is not defined in CoinCreate`,
        })
      );
      return;
    }
    const accountAddress =
      currentProfile.additional_properties?.commonshoodWallet;
    if (accountAddress != null) {
      try {
        try {
          await increaseAllowance(
            nftTemplateAddress,
            addressToAllow,
            tokenId,
            ethers,
            accountAddress
          );
          dispatch(allowNftSuccess());
        } catch (error: any) {
          logger.debug("Something unexpected happened", error);
          dispatch(allowNftFail({ error }));
        }
      } catch (error: any) {
        dispatch(allowNftFail({ error }));
      }
    }
  };
};
export const isApproved = (
  nftTemplateAddress: string,
  addressToCheck: string,
  tokenId: number
) => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    let allowed = false;
    dispatch(getIsAllowedStarted());
    const currentProfile = getState().user.currentProfile;
    const ethers = getState().ethers.ethersInstance;

    if (currentProfile == null || ethers == null) {
      dispatch(
        getIsAllowedFailed({
          error: `currentProfile is not defined in CoinCreate`,
        })
      );
      return;
    }
    const accountAddress =
      currentProfile.additional_properties?.commonshoodWallet;
    if (accountAddress != null) {
      try {
        try {
          allowed = await isAllowed(
            ethers,
            nftTemplateAddress,
            addressToCheck,
            tokenId,
            accountAddress
          );
          dispatch(getIsAllowedEnded({ isApproved: allowed }));
        } catch (error: any) {
          logger.debug("Something unexpected happened", error);
          dispatch(getIsAllowedFailed({ error }));
        }
      } catch (error: any) {
        dispatch(getIsAllowedFailed({ error }));
      }
    }
    return allowed;
  };
};
export default nftSlice.reducer;
