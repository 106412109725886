import { Grid, Typography } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"
import { MyRequestsPreorderInList } from "../PreorderInList/MyRequestsPreorderInList";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../../../store/hooks";
import { getPendingPreordersAPI } from "../../../../api/calendar";
import { Preorder } from "../../../../types/calendar.type";
import { PaginationContainer } from "../../../../components/UI/Pagination/PaginationContainer";
import { NoItemsDisplay } from "../../BasicComponents/NoItemsDisplay";

export const MyRequestsListOfPreorder = ({ shouldRefresh }: { shouldRefresh: boolean }) => {
    const account = useAppSelector(state => state.user.user?.additional_properties?.commonshoodWallet);
    const [preorders, setPreorders] = useState<Preorder[]>([]);
    const loading = useAppSelector(state => state.calendar.loading);
    const preordersPerPage = useAppSelector(state => state.calendar.preordersPerPage);
    const [numberOfPreorders, setNumberOfPreorders] = useState<number>(0);
    const [page, setPage] = useState(0);
    const user = useAppSelector(state => state.user.user);

    useEffect(() => {
        if (shouldRefresh) {
            (async () => {
                if (account) {
                    const preorders = await getPendingPreordersAPI(account, false, page, preordersPerPage);
                    if (preorders.preorders != null) {
                        setPreorders([...preorders.preorders]);
                        setNumberOfPreorders(preorders.totalPreorders);
                    }
                }
            })()
        }
    }, [shouldRefresh])

    useEffect(() => {
        (async () => {
            if (account) {
                const preorders = await getPendingPreordersAPI(account, false, page, preordersPerPage);
                if (preorders.preorders != null) {
                    setPreorders([...preorders.preorders]);
                    setNumberOfPreorders(preorders.totalPreorders);
                }
            }
        })()
    }, []);

    const handleClickOnPagination = async (selectedPage: number) => {
        await getPreordersFromMetadata(selectedPage);
    }

    const getPreordersFromMetadata = async (page: number) => {
        const address = user?.additional_properties?.commonshoodWallet;
        if (address) {
            const activePreorders = await getPendingPreordersAPI(
                address,
                false,
                page * preordersPerPage,
                preordersPerPage
            );
            setPreorders(activePreorders.preorders);
            setNumberOfPreorders(activePreorders.totalPreorders);
        }
    }
    const getTextForStepper = (preorder: Preorder) => {
        switch (preorder.status) {
            case 'Accepted':
                return 'Accettata';
            case 'Started':
                return 'Iniziata';
            case 'Completed':
                return 'Completata';
            default:
                return '';
        }
    }

    if (preorders.length > 0) {
        return (
            <Grid container style={{ position: 'relative', top: '3em' }} spacing={4}>
                {
                    (!loading) ? (
                        preorders.map((p) => {
                            return <Grid key={p.preorderID} item xs={12}>
                                <MyRequestsPreorderInList
                                    preorder={p}
                                    textUnderStepper={getTextForStepper(p)}
                                />
                            </Grid>
                        })
                    ) : (
                        <Skeleton
                            style={{
                                position: 'relative',
                                top: '1em',
                            }}
                            variant="rect"
                            width={'100%'}
                            height={'400px'}
                        />
                    )
                }
                <Grid item xs={12}  style={{marginBottom: '15em'}}>
                    <PaginationContainer pageCount={Math.ceil(numberOfPreorders / preordersPerPage)} onChange={handleClickOnPagination} />
                </Grid>
            </Grid>

        )
    } else {
        return (
            <NoItemsDisplay style={{ marginTop: '3em' }} />
        )
    }
}