import { Badge, Box, Tab, Tabs, useMediaQuery } from "@material-ui/core";
import { useContext, useEffect, useState } from "react";
import { styled } from '@material-ui/core/styles';
import { CalendarContractContext } from "../../../providers/CalendarContractProvider";
import { getPreorders } from "../../../api/calendar";
import { useAppSelector } from "../../../store/hooks";

export const MyRequestsDashboardMenu = ({ handleMenuChange }: {
    handleMenuChange: (newMenu: number) => void
}) => {
    const [value, setValue] = useState(0);
    const isMobile = useMediaQuery('(max-width: 600px)');
    const { stats, getUserStats } = useContext(CalendarContractContext);
    const preordersPerPage = useAppSelector(state => state.calendar.preordersPerPage);
    const [page, setPage] = useState(0);
    const account = useAppSelector(state => state.user.user?.additional_properties?.commonshoodWallet);

    //Workaround to fix not working stats
    const [myStats, setMyStats] = useState<{ canceled: number }>({canceled: -1});

    const handleChange = (event: any, newValue: number) => {
        setValue(newValue);
        handleMenuChange(newValue);
    };

    useEffect(() => {
        const fetchData = async () => {
            await getUserStats();
            const cancelledPreorders = await getPreorders(account!, false, page, preordersPerPage, 'Cancelled');
            const deniedPreorders = await getPreorders(account!, false, page, preordersPerPage, 'Denied');
            const canceledTotal = cancelledPreorders.numberOfPreorders + deniedPreorders.numberOfPreorders;

            setMyStats({ canceled: canceledTotal });
            console.clear();
            console.log(myStats);
        };

        fetchData();
    }, []);

    return (
        <Box style={{ borderColor: 'divider' }}>
            {
                (isMobile && stats != undefined && myStats.canceled >= 0) ? (
                    <>
                        <Tabs value={value} style={{ padding: '16px' }} onChange={handleChange} aria-label="basic tabs example" centered>
                            <CustomTab icon={
                                <Badge badgeContent={stats.pending} color={'secondary'} max={99}>
                                    <img src="/svgs/pending_request_borrower.svg"></img>
                                </Badge>
                            } />
                            <CustomTab icon={
                                <Badge badgeContent={stats.accepted + stats.started} color={'secondary'} max={99}>
                                    <img src="/svgs/active_request_borrower.svg"></img>
                                </Badge>
                            } />
                            <CustomTab icon={
                                <Badge badgeContent={stats.ended} color={'secondary'} max={99}>
                                    <img src="/svgs/completed_request_borrower.svg"></img>
                                </Badge>
                            }
                            />
                            <CustomTab icon={
                                <Badge badgeContent={myStats.canceled} color={'secondary'} max={99}>
                                    <img src="/svgs/denied_request_borrower.svg"></img>
                                </Badge>
                            } />
                        </Tabs>
                    </>
                ) : (
                    <>
                        <Tabs variant={'fullWidth'} value={value} onChange={handleChange} aria-label="basic tabs example">
                            <CustomTab label={`In attesa${stats !== undefined && myStats.canceled >= 0 && stats.pending > 0 ? ` (${stats.pending})` : ''}`} />
                            <CustomTab label={`Attive${stats !== undefined && myStats.canceled >= 0 && stats.accepted + stats.started > 0 ? ` (${stats.accepted + stats.started})` : ''}`} />
                            <CustomTab label={`Completate${stats !== undefined && myStats.canceled >= 0 && stats.ended > 0 ? ` (${stats.ended})` : ''}`} />
                            <CustomTab label={`Rifiutate${myStats !== undefined && myStats.canceled > 0 ? ` (${myStats.canceled})` : ''}`} />
                        </Tabs>
                    </>
                )
            }

        </Box>
    )
}

const CustomTab = styled(Tab)({
    textTransform: 'none',
    borderBottom: '1px solid black',
    width: '100%',
    ['@media (max-width: 600px)']: {
        width: '25%',
    },
    '& .MuiBadge-badge': {
        background: 'black',
        color: 'white',
        marginTop: '5px',
    }
})