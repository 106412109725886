import React, { useEffect, useState } from 'react';

import { Box, Divider, Grid, IconButton, Menu, Tooltip, Typography } from "@material-ui/core";

//style injection
import { makeStyles, useTheme } from "@material-ui/core/styles";

//Custom components
import Notification from './Notification';

import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import Icon from "@material-ui/core/Icon";
import { RealtimeNotificationType } from "../../api/notificationAPI";
import { useTranslation } from "react-i18next";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import { notificationMarkAsRead, notificationRemoveAllFromCurrentlyListed } from "../../store/slices/notificationSlice";
import { useAppDispatch } from "../../store/hooks";

const useStyles = makeStyles((theme) => ({
    icon: {
        [theme.breakpoints.down('sm')]:
        {
            fontSize: '0.7em'
        }
    },
    emptyList: {
        alignContent: "center"
    },
    menu: {
        width: '100vw',
        '& .MuiPaper-root': {
            borderRadius: 20,
            border: 'rgba(218, 93, 62, 1)',
            borderWidth: 2,
            borderStyle: 'solid',
            maxWidth: 400,
        }
    },
}));


type NotificationMenuListProps = {
    notificationList: RealtimeNotificationType[],
    id: string,
    anchorEl: EventTarget & Element | null,
    open: boolean,
    onClose: () => void
};


const NotificationMenuList = (props: NotificationMenuListProps) => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const history = useHistory();
    const { t } = useTranslation('NotificationMessages');
    const [numberOfNotf, setNumberOfNotf] = useState(5)
    let notfList = [...props.notificationList].slice(0, numberOfNotf);

    useEffect(() => {
        console.log(notfList)
    }, [notfList])

    return (
        <Menu
            id={props.id}
            anchorEl={props.anchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={props.open}
            onClose={props.onClose}
            className={classes.menu}
        >
            <Box textAlign={'center'} margin={2}>
                <Typography variant={'h5'}>Le mie notifiche</Typography>
            </Box>
            <CustomDivider backgroundColor='rgba(218, 93, 62, 1)' />
            {
                notfList.length > 0 ?
                    notfList.map((item: RealtimeNotificationType, index: number) => {
                        return (
                            <>
                                {
                                    (item.message.length > 0) && (
                                        <Typography component={'span'} key={index}>
                                            <Notification
                                                params={item}
                                            />
                                        </Typography>
                                    )
                                }
                            </>
                        )
                    }) : <Typography style={{ marginBottom: '25px', marginTop: '10px' }}>
                        <Icon>info</Icon> {t('noNotifications')}
                    </Typography>
            }
            <Box textAlign={'center'} margin={1} >
                <Button style={{
                    color: 'rgba(218, 93, 62, 1)',
                    textTransform: 'none',
                    fontSize: 20
                }} onClick={() => {
                    history.push('/messages')
                }}> {t('goToNotificationsHistory')}</Button>
            </Box>
        </Menu>
    )
}


const CustomDivider = ({ backgroundColor }: {
    backgroundColor: string
}) => {
    return (
        <Box textAlign={'center'} margin={2}>
            <Divider style={{
                backgroundColor: backgroundColor,
            }} />
        </Box>
    )
}

export default (NotificationMenuList);
