import { Grid, Typography, makeStyles } from "@material-ui/core";

import Tab4Img1 from "../../../../assets/img/tutorial/tab4img1.svg"


const styles = makeStyles({
    tutorialPageTitle: {
        fontFamily: 'Be Vietnam Pro',
        fontSize: '30px',
        fontWeight: 600,
        lineHeight: '38px',
        letterSpacing: '0em',
        textAlign: 'center',
    },
    tutorialText: {
        fontFamily: 'Be Vietnam Pro',
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '20px',
        letterSpacing: '0em',
        textAlign: 'center',
    },
    tutorialPageSubtitle: {
        fontFamily: 'Be Vietnam Pro',
        fontSize: '24px',
        fontWeight: 600,
        lineHeight: '30px',
        letterSpacing: '0em',
        textAlign: 'center',
    }
});


export const MobileTab7 = () => {
    const style = styles();

    return (
        <>
            <Typography className={style.tutorialPageTitle} style={{ height: 0 }}>Chiedere in prestito</Typography>
            <Grid container direction="column" justifyContent="center" alignContent="center">
                <Grid item><img src={Tab4Img1} /></Grid>
            </Grid>
            <Typography className={style.tutorialPageSubtitle} style={{ height: 0 }}>
                Richiesta di prestito
            </Typography>
            <Typography className={style.tutorialText}>
                Puoi visualizzare le richieste di prestito che hai inviato nella sezione “Chiedo in prestito”. Qui puoi anche vedere quali richieste sono in attesa e quali sono completate
            </Typography>
        </>
    );
}