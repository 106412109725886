import { Grid, useMediaQuery } from "@material-ui/core";
import { Title } from "../BasicComponents/Title";
import { ListOfCards } from "./ListOfCards";
import { useTranslation } from "react-i18next";
import { useContext, useEffect, useState } from "react";
import { LibraryOfThingsContractContext } from "../../../providers/LibraryOfThingsContractProvider";
import { Item } from "../../../types/libofthings.type";

export const NewArrivalsContainer = () => {
    const { t } = useTranslation(['LibOfThings']);
    const [items, setItems] = useState<Item[]>([]);
    const { getNewArrivals } = useContext(LibraryOfThingsContractContext);
    const useMobileQuery = useMediaQuery('(max-width: 600px)');
    const title = 'Nuovi arrivi';



    useEffect(() => {
        (async () => {
            const items = await getNewArrivals(0);
            setItems(items);
        })();
    }, []);

    return (
        <>
        <Grid container>
            <Grid item xs={12}>
                <Title title={title} />
            </Grid>
            <Grid item xs={12} style={{marginTop: '1em'}}>
                <ListOfCards items={items} />
            </Grid>
        </Grid>
        </>
    )
}