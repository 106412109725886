import { createContext, useEffect } from "react";
import axiosFirstlifeOauth from "../utilities/backend/axios-firstlife-oauth";
import config from "../config";

export type FirstLifeContext = {
    getUserByAddress: (address: string) => Promise<any>;
}

export const FirstLifeContextImpl = createContext<FirstLifeContext>({
    getUserByAddress: async (address: string) => { return {}; },
});
export const FirstLifeProvider = (props: any) => {
    const children = props.children;

    const getUserByAddress = async (wallet: string) => {
        const res = await axiosFirstlifeOauth.get(`/v1/users/info?commonshoodWallet=${wallet}&clientId=${config.network.authserver['firstlife_token'].client_id}&onlyWithChWallet=true`);
        return {
            first_name: res.data.items[0].first_name,
            last_name: res.data.items[0].last_name,
            email: res.data.items[0].email
        }
    }

    const values = {
        getUserByAddress
    }

    return <FirstLifeContextImpl.Provider value={values}> {children} </FirstLifeContextImpl.Provider>;
};
