import { Grid, Typography, useMediaQuery } from "@material-ui/core";
import { Preorder, PreorderStatusStrings, PreorderStatuses } from "../../../../../types/calendar.type"
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import { cancelPreorder, startPreorder } from "../../../../../store/slices/calendarSlice";
import toast from "react-hot-toast";
import { BlackButton } from "../../../../../components/Buttons/BlackButton";
import { useEffect, useState } from "react";
import { isApproved } from "../../../../../store/slices/nftSlice";
import config from "../../../../../config";
import { Item } from "../../../../../types/libofthings.type";

export const ButtonsToHandlePreorder = ({ preorder, callback, openDialogFallback, item, collectionAddress }: {
    preorder: Preorder,
    callback: any,
    openDialogFallback: any,
    item: Item,
    collectionAddress: string
}) => {
    const dispatch = useAppDispatch();

    const handleStartPreorder = async () => {
        if (preorder.startDate > Date.now() / 1000) {
            toast.error('Non puoi iniziare un ordine prima della data di inizio');
            return;
        }
        try {
            await dispatch(startPreorder(preorder.preorderID));
            callback();
        } catch (e) {
            console.log(e);
            toast.error('Errore nell\'inizio dell\'ordine');
        }

    }

    const handleCancelLending = async () => {
        const toastid = toast.loading('Annullo l\'ordine');
        try {
            await dispatch(cancelPreorder(preorder.preorderID, true));
            callback();
        } catch (e) {
            console.log(e);
            toast.error('Errore nell\'annullamento dell\'ordine');
        } finally {
            toast.dismiss(toastid);
        }
    }

    return (
        <ButtonsToHandlePreorderDesktop
            preorder={preorder}
            openDialogFallback={openDialogFallback}
            handleStartPreorder={handleStartPreorder}
            handleCancelLending={handleCancelLending}
            item={item}
            collectionAddress={collectionAddress}
        />
    );
}

const ButtonsToHandlePreorderDesktop = ({ preorder, openDialogFallback, handleStartPreorder, handleCancelLending, item, collectionAddress }: {
    preorder: Preorder,
    openDialogFallback: any,
    handleStartPreorder: () => Promise<void>,
    handleCancelLending: () => Promise<void>,
    item: Item,
    collectionAddress: string
}) => {
    const profile = useAppSelector(state => state.user.currentProfile);
    const [userDidApprove, setUserDidApprove] = useState<boolean>(false);
    const dispatch = useAppDispatch();

    useEffect(() => {
        checkIfAddressIsApproved();
    }, []);

    const checkIfAddressIsApproved = async () => {
        (async () => {
            const currentAddressApprovedEndOfPreorder = await dispatch(isApproved(collectionAddress, config.smartContracts.CALENDAR_ADDRESS, item.idInCollection))
            if (currentAddressApprovedEndOfPreorder != null) {
                setUserDidApprove(currentAddressApprovedEndOfPreorder);
            }
        })();
    }


    return (
        <Grid container style={{ textAlign: 'center' }}>
            {
                (preorder.status === PreorderStatuses.Started
                    && preorder.borrower === profile?.additional_properties?.commonshoodWallet) && (
                    <Grid item>
                        <Typography variant="body1" align="center">Hai riconsegnato l'oggetto? Clicca sul tasto termina per concludere il prestito.</Typography>
                    </Grid>
                )
            }

            <Grid item xs={12}>
                {
                    (preorder.status === PreorderStatuses.Accepted) && (
                        <>
                            <Typography variant="body1" style={{ fontWeight: 'bold', margin: '16px' }}>Hai consegnato l'oggetto? Clicca sul tasto conferma per iniziare il prestito</Typography>
                            <BlackButton style={{ maxWidth: '264px', color: 'white' }} onClick={handleStartPreorder} text={'Conferma'} />

                        </>
                    )
                }
                {
                    (preorder.status === PreorderStatuses.Started) && (
                        <>
                            {
                                userDidApprove ? <>
                                    <Typography variant="body1">&Egrave; stato richiesto il termine del prestito. In attesa della tua risposta.</Typography>
                                    <BlackButton style={{ maxWidth: '264px', color: 'white', marginTop: '16px' }} onClick={openDialogFallback} text={'Accetta termine'} />
                                </> : (
                                    <Typography variant="body1" style={{ fontWeight: 'bold', margin: '16px' }}>Attendi la fine del prestito</Typography>
                                )
                            }
                        </>

                    )
                }
            </Grid>
        </Grid>
    )
}