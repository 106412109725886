import { Button, Grid, Typography, makeStyles } from "@material-ui/core";
import { ChangeEvent, useEffect, useState } from "react";

const styles = makeStyles({
    inputField: {
        border: '1px solid rgba(0, 0, 0, 1)',
        width: '100px',
        height: '38.5px',
    },
    buttonMinus: {
        border: '1px solid rgba(0, 0, 0, 1)',
        borderTopRightRadius: '0',
        borderBottomRightRadius: '0',
        borderRadius: '10px',
        minWidth: '30px',
        height: '37px',
        cursor: 'pointer'
    },
    buttonPlus: {
        border: '1px solid rgba(0, 0, 0, 1)',
        borderTopLeftRadius: '0',
        borderBottomLeftRadius: '0',
        borderRadius: '10px',
        minWidth: '30px',
        height: '37px',
        cursor: 'pointer'
    }
})

export const SelectTokenQuantity = ({ fallback }: {
    fallback: (amount: number) => void
}) => {
    const classes = styles();
    const [amount, setAmount] = useState(0);

    useEffect(() => {
        fallback(amount);
    }, [amount])

    return (
        <Grid container>
            <Grid item xs={12} sm={3}>
                <Typography variant="body1">Seleziona la quantità da inviare</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
                <button onClick={() => {
                    if (amount > 0)
                        setAmount(amount - 1);
                }} className={classes.buttonMinus}>-</button>
                <input
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setAmount(parseInt(e.target.value))} value={amount} type="number"
                    min={0}
                    style={{
                        height: '33px',
                        border: '1px solid rgba(0, 0, 0, 1)'
                    }}></input>
                <button className={classes.buttonPlus} onClick={() => {
                    setAmount(amount + 1);
                }}>+</button>
            </Grid>
        </Grid >
    );
}