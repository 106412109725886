import { MouseEvent, useContext, useState } from 'react';
import { AppBar, Box, FormControl, Grid, Input, InputAdornment, Theme, createStyles, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import NotificationBell from "../../../RealTimeNotification/NotificationBell";
import { useHistory } from 'react-router-dom';
import { Profile } from './Profile';
import { TutorialContext } from '../../../../providers/TutorialProvider';
import SearchIcon from '@material-ui/icons/Search';
import { LibraryOfThingsContractContext } from '../../../../providers/LibraryOfThingsContractProvider';
import { Item } from '../../../../types/libofthings.type';

const MainAppBar = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

    return (
        <>
            {
                isMobile ? (
                    <MobileHeader />
                ) : (
                    <DesktopHeader />
                )
            }
        </>
    );
}

export default MainAppBar;

const MobileHeader = () => {
    const [anchorEl, setAnchorEl] = useState<EventTarget & Element | null>(null);
    const [profileAnchorEl, setProfileAnchorEl] = useState<EventTarget & Element | null>(null);
    const history = useHistory();
    const { openTutorialMobile } = useContext(TutorialContext);
    const handleProfile = (event: MouseEvent) => {
        setProfileAnchorEl(event.currentTarget);
    }

    const handleMenu = (event: MouseEvent) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <AppBar style={{
            background: 'rgba(248, 248, 248, 1)',
            boxShadow: 'none',
            position: 'relative',
            width: '96%',
            margin: 'auto'
        }}>
            <Grid container style={{
                padding: 10,
                marginTop: 16
            }}>
                <Grid item xs={1}>
                    <img onClick={openTutorialMobile} src="/svgs/tutorial_logo.svg" style={{ width: '40px', height: '40px' }} />
                </Grid>
                <Grid item xs={5}>
                </Grid>
                <Grid item xs={5} style={{
                    textAlign: 'right',
                    cursor: 'pointer'
                }}>
                    <Box marginRight={1.5}>
                        <NotificationBell anchorEl={anchorEl} handleMenu={handleMenu} onClose={handleClose} />
                    </Box>
                </Grid>
                <Grid item xs={1} style={{
                    textAlign: 'center',
                    padding: 4,
                    cursor: 'pointer'
                }}>
                    <img src="/svgs/profile.svg" alt="Profile" width={28} height={28} onClick={handleProfile} />
                    <Profile anchorEl={profileAnchorEl} id="profile-menu" open={Boolean(profileAnchorEl)} onClose={() => setProfileAnchorEl(null)} />
                </Grid>
                <Grid item xs={12} >
                    <SearchTextInput />
                </Grid>
            </Grid>
        </AppBar>
    )
}

const DesktopHeader = () => {
    const [anchorEl, setAnchorEl] = useState<EventTarget & Element | null>(null);
    const [profileAnchorEl, setProfileAnchorEl] = useState<EventTarget & Element | null>(null);
    const history = useHistory();
    const handleProfile = (event: MouseEvent) => {
        setProfileAnchorEl(event.currentTarget);
    }

    const handleMenu = (event: MouseEvent) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const goToHome = () => {
        history.push('/');
    }
    return (
        <AppBar style={{
            background: 'rgba(248, 248, 248, 1)',
            boxShadow: 'none',
            position: 'relative',
            width: '96%',
            margin: 'auto'
        }}>
            <Grid container>
                <Grid item xs={3}>
                    <img onClick={goToHome} style={{ cursor: 'pointer' }} src="/svgs/apicciridda.jpg" alt="CommonsHood Logo" width={123} height={123} />
                </Grid>
                <Grid item xs={7} style={{
                    textAlign: 'end',
                }} >
                    <SearchTextInput />
                </Grid>
                <Grid item xs={1} style={{
                    textAlign: 'end',
                    cursor: 'pointer'
                }}>
                    <NotificationBell anchorEl={anchorEl} handleMenu={handleMenu} onClose={handleClose} />
                </Grid>
                <Grid item xs={1} style={{
                    textAlign: 'center',
                    padding: 4,
                    cursor: 'pointer'
                }}>
                    <img src="/svgs/profile.svg" alt="Profile" width={28} height={28} onClick={handleProfile} />
                    <Profile anchorEl={profileAnchorEl} id="profile-menu" open={Boolean(profileAnchorEl)} onClose={() => setProfileAnchorEl(null)} />
                </Grid>
            </Grid>
        </AppBar>
    )
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            border: '1px solid rgba(24, 27, 29, 1)',
            borderRadius: '10px',
            minWidth: 300,
            background: 'white',
            padding: 4,
            '@media (max-width: 600px)': {
                width: '100%',
                top: '16px'
            }
        },
    }),
);

const SearchTextInput = () => {
    const classes = useStyles();
    const [name, setName] = useState<string>('');
    const { getItemsByName } = useContext(LibraryOfThingsContractContext);
    const [suggestions, setSuggestions] = useState<string[]>([]);
    const [items, setItems] = useState<Item[]>([]);

    const history = useHistory();

    const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
        const items = await getItemsByName(event.target.value, 0);
        const itemsName = items.map((item: Item) => {
            return item.name;
        })
        setSuggestions(itemsName);
        setItems(items);
    }

    const openItemPage = async (itemIndexInArray: number) => {
        const item = items[itemIndexInArray];
        if (item.name != null) {
            const q = `/items/search?name=${item.name}`;
            history.push(q);
            setName('');
            setSuggestions([]);
        } else {
            console.log('Item name is null')
        }
    }

    const openDefaultItemPage = async () => {
        const q = `/items/search?name=${name}`;
        history.push(q);
        setName('');
        setSuggestions([]);
    }

    return (
        <FormControl className={classes.formControl} >
            <Input
                style={{ zIndex: 10 }}
                inputProps={{ disableUnderline: true }}
                onChange={handleChange}
                placeholder='Cerca un oggetto...'
                disableUnderline={true}
                id="input-with-icon-adornment"
                endAdornment={
                    <InputAdornment position="start">
                        <SearchIcon
                            style={{ cursor: 'pointer' }}
                            onClick={() => { openDefaultItemPage(); }}
                        />
                    </InputAdornment>
                }
            />
            <Box color={'black'} position={'absolute'} style={{ background: 'white', width: '100%', zIndex: 9, borderRadius: '0px 0px 10px 10px', top: '41px' }}>
                {
                    suggestions.map((suggestion: string, index: number) => {
                        return (
                            <Box key={index} style={{
                                padding: 8,
                                textAlign: 'left',
                                cursor: 'pointer',
                            }} onClick={() => {
                                openItemPage(index);
                            }}>
                                {suggestion}
                            </Box>
                        )
                    })
                }
            </Box>
        </FormControl>
    )
}