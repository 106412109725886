import { Grid, useMediaQuery } from "@material-ui/core"
import { AccountAction, AccountActionWithDashedBorder } from "../../LibOfThings/MyAccount/AccountActions"
import { useHistory } from "react-router-dom";

export const CouponActionsHome = () => {
    const isMobile = useMediaQuery('(max-width:600px)');
    const history = useHistory();

    return(
        <Grid container spacing={2} style={{ width: '100%', textAlign: 'center' }}>
            <Grid item xs={6} sm={3}>
                <AccountAction
                    link={'/user/items'}
                    title={'Invia'}
                />
            </Grid>
            {
                !isMobile && (
                    <img src="/svgs/line.svg" alt="line" />
                )
            }
            <Grid item xs={12} sm={3} onClick={() => { history.push('/coupont/create') }}>
                <AccountActionWithDashedBorder
                    link={'/items/'}
                    title={'Crea un nuovo coupon'}
                />
            </Grid>
        </Grid>
    )
}