import Home from '@material-ui/icons/Home';
import ExitToApp from '@material-ui/icons/ExitToApp';
import Notifications from '@material-ui/icons/Notifications';
import StorefrontIcon from '@material-ui/icons/Storefront';
import { TFunction } from "i18next";

const menuConfig = (t?: TFunction) => {
    return {
        menu: {
            title: 'CommonsHood Menu',
            items: {
                'home': {
                    icon: <Home />,
                    path: '/'
                },
                'library_of_things': {
                    icon: <StorefrontIcon />,
                    path: '/libraryOfThings',
                    concurrent: true
                },
                'messages': {
                    icon: <Notifications />,
                    path: '/messages'
                },
                'logout': {
                    icon: <ExitToApp />,
                    path: '/logout'
                }
            }
        }
    };

};


export default menuConfig;