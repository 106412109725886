import axiosFirstlifeOauth from '../utilities/backend/axios-firstlife-oauth';
import {logger} from "../utilities/logger/logger";
import {AxiosResponse} from "axios";

import type {UserData} from "../types/user.type";
import config from "../config";

type UserDataResponse = Omit<UserData, "id" | "name"> & {
    _id: string; //in the response from the server we receive the id as _id
    //end the name is divided into two different fields:
    last_name: string;
    first_name: string;
};

export const getUserDataById = async (userIdRaw: string | null): Promise<UserData | undefined> => {
    //we get userID in the form 1234@FIRSTLIFE, we need just the part before @
    const userId = userIdRaw?.split('@')[0];
    try {
        const res: AxiosResponse<UserDataResponse> = await axiosFirstlifeOauth(`/v1/users/${userId}/info`);
        const user = res.data;
        return {
            ...user,
            id: user._id,
            name: `${user.first_name} ${user.last_name}`,
        }
    }catch(e: any){
        logger.log('Error', e, ' trying to retrive user with id: ', userIdRaw);
        return undefined;
    }
}


export const getUsersList = async () : Promise<UserData[]> => {
    try {
        const res : AxiosResponse<any> = await axiosFirstlifeOauth.get(`/v1/users/info?clientId=${config.network.authserver['firstlife_token'].client_id}&onlyWithChWallet=true`);
        //Replacing _id key with id in each element
        return res.data.items.map( (user: any) => {
            return {
                ...user,
                id: user._id,
                name: `${user.first_name} ${user.last_name}`,
            }
        });
    }catch (error: any) {
        logger.debug('Error in getUsersList, ', error);
        return [];
    }
}

/**
 * Adds the public address of a wallet inside the profile of a user in FL-oauth
 * @param wallet the string identifying the public address of the user's wallet
 * @param userIdRaw
 */
export const addPropertyToUserProfile = async (wallet: string, userIdRaw: string) : Promise<boolean> => {
    try {
        const userId = userIdRaw?.split('@')[0];
        await axiosFirstlifeOauth.post(`/v1/users/${userId}/additional-properties`, {
            'commonshoodWallet': wallet
        });
        return true;
    }catch (error: any) {
        logger.log(`Error trying to add property wallet: ${wallet} - to user profile: `, error);
        return false;
    }
}

export type UserNameType= {
    first_name:string,
    last_name: string
    email:string
}

export const getUserNameByAddress= async (wallet:string): Promise<UserNameType|undefined>=>{
    try {
        const userName = await axiosFirstlifeOauth.get(`/v1/users/info?commonshoodWallet=${wallet}&clientId=${config.network.authserver['firstlife_token'].client_id}&onlyWithChWallet=true`);
        return {
            first_name:userName.data.items[0].first_name,
            last_name: userName.data.items[0].last_name,
            email: userName.data.items[0].email
        }
    }catch (error: any) {
        logger.log(`Error trying to read details of the user: ${wallet}: `, error);
        return undefined;
    }

}
