import { Grid, Typography, makeStyles } from "@material-ui/core"
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

const style = makeStyles({
    root: {
        '@media(max-width:600px)': {
            paddingLeft: '16px'
        }
    }
})

export const UploadItemTitle = ({ text }: {
    text: string
}) => {
    const classes = style();
    return (
        <Grid container
            alignItems="center" // Centra verticalmente
            className={classes.root}
        >
            <Grid item xs={1} style={{
                position: 'relative',
                textAlign: 'center'
            }}
            >
                <ArrowBackIosIcon />
            </Grid>
            <Grid item xs={9}>
                <Typography variant='h6'>
                    {text}
                </Typography>
            </Grid>

        </Grid>
    )
}