const ITEM_CATEGORIES = [
  {
    value: "ARTE MUSICA E CULTURA",
    text: "Arte, musica e cultura",
  },
  {
    value: "ELETTRONICA",
    text: "Elettronica",
  },
  {
    value: "CARTOLERIA/CANCELLERIA",
    text: "Biciclette / Trasporti",
  },
  {
    value: "GIOCHI",
    text: "Giochi",
  },
  {
    value: "MATERIALE SCOLASTICO",
    text: "Materiale scolastico",
  },
  {
    value: "ACCESSORI",
    text: "Accessori",
  },

  {
    value: "TRASPORTI",
    text: "Trasporti",
  },
  {
    value: "MATERIALE SPORTIVO",
    text: "Materiale sportivo",
  },
  {
    value: "VARIE",
    text: "Varie",
  },
] as const;

export default ITEM_CATEGORIES;
