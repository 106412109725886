import { Grid, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { useContext, useEffect, useState } from "react"
import { useAppSelector } from "../../../../store/hooks";
import { Preorder, PreorderStatus } from "../../../../types/calendar.type";
import { getActivePreordersAPI } from "../../../../api/calendar";
import { MyRequestsPreorderInList } from "../PreorderInList/MyRequestsPreorderInList";
import { PaginationContainer } from "../../../../components/UI/Pagination/PaginationContainer";
import { CalendarContractContext } from "../../../../providers/CalendarContractProvider";
import { CustomToaster } from "../../../Notifications/CustomToaster";
import { NoItemsDisplay } from "../../BasicComponents/NoItemsDisplay";

export const ListOfActivePreorders = ({ shouldRefresh }: {
    shouldRefresh: boolean
}) => {
    const [preordersToShow, setPreordersToShow] = useState<Preorder[]>([]);
    const [numberOfPreorders, setNumberOfPreorders] = useState<number>(0);
    const { preordersPerPage } = useContext(CalendarContractContext);
    const loading = useAppSelector(state => state.calendar.loading);
    const user = useAppSelector(state => state.user.user);

    useEffect(() => {
        console.log('should refresh', shouldRefresh)
        if (shouldRefresh) {
            (async () => {
                console.log('refreshing active preorders');
                await getPreordersFromMetadata(0);
            })()
        }
    }, [shouldRefresh])

    useEffect(() => {
        (async () => {
            await getPreordersFromMetadata(0);
        })()
    }, [])

    const handleClickOnPagination = async (selectedPage: number) => {
        await getPreordersFromMetadata(selectedPage);
    }

    const getPreordersFromMetadata = async (page: number) => {
        const address = user?.additional_properties?.commonshoodWallet;
        if (address) {
            const activePreorders = await getActivePreordersAPI(
                address,
                false,
                page * preordersPerPage,
                preordersPerPage
            );
            setPreordersToShow(activePreorders.preorders.preorders);
            setNumberOfPreorders(activePreorders.preorders.numberOfPreorders);
        }
    }

    const getTextForStepper = (preorder: Preorder) => {
        switch (preorder.status) {
            case 'Accepted':
                return 'Accettata';
            case 'Started':
                return 'Iniziata';
            case 'Completed':
                return 'Completata';
            default:
                return '';
        }
    }

    if (preordersToShow.length > 0) {
        return (
            <Grid container style={{ position: 'relative', top: '3em' }} spacing={4}>
                <CustomToaster />
                {
                    (!loading) ? (
                        preordersToShow.map((p) => {
                            return <Grid style={{ marginBottom: '100px' }} key={p.preorderID} item xs={12}>
                                <MyRequestsPreorderInList
                                    preorder={p}
                                    textUnderStepper={getTextForStepper(p)}
                                />
                            </Grid>
                        })
                    ) : (
                        <Skeleton
                            style={{
                                position: 'relative',
                                top: '1em',
                            }}
                            variant="rect"
                            width={'100%'}
                            height={'400px'}
                        />
                    )
                }
                <Grid item xs={12}  style={{marginBottom: '15em'}}>
                    <PaginationContainer pageCount={Math.ceil(numberOfPreorders / preordersPerPage)} onChange={handleClickOnPagination} />
                </Grid>
            </Grid>
        )
    } else {
        return (
            <NoItemsDisplay style={{ marginTop: '3em' }} />
        )
    }
}