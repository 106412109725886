import { Grid, Typography, makeStyles, useMediaQuery } from "@material-ui/core";
import { useContext, useEffect, useState } from "react";
import { MyRequestsDashboardMenu } from "./MyRequestsDashboardMenu";
import { useHistory } from "react-router-dom";
import { UploadItemTitle } from "../UploadItem/UploadItemTitle";
import { ListOfActivePreorders } from "./Active/ListOfActivePreorders";
import { ListOfPreordersCancelled } from "./Cancelled/ListOfCancelledPreorders";
import { ListOfEndedPreorders } from "./Ended/ListOfEndedPreorders";
import { MyRequestsListOfPreorder } from "./Pending/MyRequestsListOfPreorder";
import { getAddressFromSymbolAPI } from "../../../api/nftAPI";
import toast from "react-hot-toast";
import { styles } from "@material-ui/pickers/views/Calendar/Calendar";
import { CalendarContractContext } from "../../../providers/CalendarContractProvider";
import { TutorialFab } from "../Tutorial/TutorialFab";
import { TutorialContext } from "../../../providers/TutorialProvider";
import theme from "../../../theme/theme";

const mainContainerStyle = makeStyles({
  root: {
    position: "relative",
    "@media (min-width: 837px)": {
      left: "0.2em",
    },
  },
  mobileTabTitle: {
    margin: '0 auto',
    '@media (min-width:600px)': {
      display: 'none',
    }
  },
});

export const MyRequestsContainer = () => {
  const useMainContainerStyle = mainContainerStyle();
  const { openTutorialDesktop, openTutorialMobile } = useContext(TutorialContext);
  const [activeMenu, setActiveMenu] = useState(0); // 0 is active, 1 is pending, 2 is completed, 3 is canceled
  const history = useHistory();
  const [preorderIDFromQuery, setPreorderIDFromQuery] = useState<number | null>(null);
  const [preorderStatusFromQuery, setPreorderStatusFromQuery] = useState<string | null>(null);
  const [refreshingItems, setRefreshingItems] = useState(false);
  const [refreshingPending, setRefreshingPending] = useState(false);
  const [refreshActive, setRefreshActive] = useState(false);
  const [refreshEnded, setRefreshEnded] = useState(false);
  const [refreshCancelled, setRefreshCancelled] = useState(false);
  const { getUserStats } = useContext(CalendarContractContext);
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  useEffect(() => {
    readQueryParams();
  }, []);

  const readQueryParams = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const preorderID = urlParams.get('requestID');
    const status = urlParams.get('status');
    if (preorderID != null && status != null) {
      setPreorderIDFromQuery(parseInt(preorderID));
      setPreorderStatusFromQuery(status);
    }
  }

  useEffect(() => {
    if (preorderIDFromQuery != null && preorderStatusFromQuery != null) {
      changeMenuAccordingToQueryParams(preorderStatusFromQuery);
    }
  }, [preorderIDFromQuery, preorderStatusFromQuery])

  const changeMenuAccordingToQueryParams = (status: string) => {
    if (status === 'active') {
      setActiveMenu(0);
    } else if (status === 'pending') {
      setActiveMenu(1);
    } else if (status === 'completed') {
      setActiveMenu(2);
    } else if (status === 'canceled') {
      setActiveMenu(3);
    }
  }


  const refresh = () => {
    (async () => {
      await getUserStats();
    })()
    switch (activeMenu) {
      case 0:
        setRefreshingPending(true);
        break;
      case 1:
        setRefreshActive(true);
        break;
      case 2:
        setRefreshEnded(true);
        break;
      case 3:
        break;
    }
  }


  const handleMenuChange = (menuToActivate: number) => {
    setActiveMenu(menuToActivate);
  };

  useEffect(() => {
    if (refreshingItems || refreshingPending || refreshActive || refreshEnded || refreshCancelled) {
      setRefreshingItems(false);
      setRefreshingPending(false);
      setRefreshActive(false);
      setRefreshEnded(false);
      setRefreshCancelled(false);
    }
  }, [refreshingItems, refreshingPending, refreshActive, refreshEnded, refreshCancelled])

  const tutorialFabCallback = () => {
    if (isMobile)
      openTutorialMobile();
    else
      openTutorialDesktop();
  }

  const goToItemPage = async (symbol: string, itemID: number) => {
    const collection = await getAddressFromSymbolAPI(symbol);
    if (collection != null && itemID != null) {
      const q = `/items/${collection}/${itemID}`;
      history.push(q);
    } else {
      toast.loading(`Sto caricando l'oggetto`, {
        duration: 5000
      });
    }
  };

  return (
    <>
      <Grid container className={useMainContainerStyle.root} spacing={1}>
        <Grid item xs={12}>
          <Grid item xs={12} style={{ cursor: "pointer" }}>
            <Grid container>
              <Grid item xs={10} sm={3} md={2} lg={2} onClick={() => history.push('/')} >
                <UploadItemTitle text={'Chiedo in prestito'} />
              </Grid>
              <Grid item xs={2} sm={9} md={9} lg={9} onClick={() => refresh()} style={{
                zIndex: 100,
              }}>
                <img style={{
                  cursor: 'pointer'
                }} src="/svgs/reload.svg" alt="reload" width={24} height={28} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <MyRequestsDashboardMenu handleMenuChange={handleMenuChange} />
        </Grid>
        {
          activeMenu === 0 && (
            <>
              <Typography className={useMainContainerStyle.mobileTabTitle}>In attesa</Typography>
              <MyRequestsListOfPreorder shouldRefresh={refreshingPending} />
            </>
          )
        }
        {
          activeMenu === 1 && (
            <>
              <Typography className={useMainContainerStyle.mobileTabTitle}>Attive</Typography>
              <ListOfActivePreorders shouldRefresh={refreshActive} />
            </>
          )
        }
        {
          activeMenu === 2 && (
            <>
              <Typography className={useMainContainerStyle.mobileTabTitle}>Completate</Typography>
              <ListOfEndedPreorders shouldRefresh={refreshEnded} />
            </>
          )
        }
        {
          activeMenu === 3 && (
            <>
              <Typography className={useMainContainerStyle.mobileTabTitle}>Cancellate</Typography>
              <ListOfPreordersCancelled shouldRefresh={refreshCancelled} isLender={false} />
            </>
          )
        }

      </Grid>
      <TutorialFab callback={tutorialFabCallback} />
    </>
  );
};
